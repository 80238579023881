// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getReportedCommentsList = createAsyncThunk(
  'GET_REPORTEDCOMMENTS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getreportedcomments', params})
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const ReportCommentsSlice = createSlice({
  name: 'REPORTCOMMENTS',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false
  },
  reducers: {
  },
  extraReducers:{
    [getReportedCommentsList.pending]: (state) => {
      state.loading = true
    },
    [getReportedCommentsList.rejected]: (state) => {
      state.loading = false
    },
    [getReportedCommentsList.fulfilled]: (state, action) => {
      state.data = action.payload.response.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.loading = false
    }
  }
})

export default ReportCommentsSlice.reducer
