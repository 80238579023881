// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import userSlice from "./user_slice"
import auth_slice from "./auth_slice"
import subscription_slice from "./subscription_slice"
import credit_slice from "./credit_slice"
import report_comments_slice from "./report_comments_slice"
import setting_slice from "./setting_slice"
import content_slice from "./content_slice"
import support_message_slice from "./support_message_slice"
import history_slice from "./history_slice"
import payout_slice from "./payout_slice"
import homeSlice from "./home_slice"

const rootReducer = {
    homeSlice,
    navbar,
    layout,
    auth_slice,
    userSlice,
    subscription_slice,
    credit_slice,
    report_comments_slice,
    setting_slice,
    content_slice,
    support_message_slice,
    history_slice,
    payoutReducer: payout_slice
}

export default rootReducer
