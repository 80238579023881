import { Package, Users, CreditCard, MessageSquare, Settings, File, DollarSign, Home } from "react-feather"

export default [
  {
    id: "Home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/home`
  },
  {
    id: "users",
    title: "All Platform Users",
    icon: <Users size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/users`
  },
  {
    id: "contents",
    title: "All Content",
    icon: <File size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/contents`
  },
  {
    id: "approvedcontent",
    title: "Approved Content",
    icon: <File size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/approvedcontent`
  },
  {
    id: "pendingcontents",
    title: "Pending Content",
    icon: <File size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/pendingcontent`
  },
  {
    id: "rejectedcontents",
    title: "Rejected Content",
    icon: <File size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/rejectedcontent`
  },
  // {
  //   id: "subscriptionsplan",
  //   title: "Subscriptions Plans",
  //   icon: <Package size={20} />,
  //   navLink: `${process.env.REACT_APP_URL_PREFIX}/subscriptionsplans`
  // },
  {
    id: "subscriptionsplan",
    title: "Subscribed Users",
    icon: <Users size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/subscriptionsusers`
  },
  {
    id: "creditplans",
    title: "Plans",
    icon: <CreditCard size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/creditplans`
  },
  {
    id: "reportedcomments",
    title: "Reported Comments",
    icon: <MessageSquare size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/reportedcomments`
  },
  {
    id: "adminhistory",
    title: "History",
    icon: <MessageSquare size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/history`
  },
  {
    id: "creditsettings",
    title: "Settings",
    icon: <Settings size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/settings`
  },
  {
    id: "payouts",
    title: "Payouts",
    icon: <DollarSign size={20} />,
    navLink: `${process.env.REACT_APP_URL_PREFIX}/payouts`
  }
]
