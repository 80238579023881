import React, { Fragment, useEffect, useState } from 'react'

// third party
import { Alert, Row, Col, Badge, Button } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'
// import {} from 'react-feather'

// ** redux
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from '../../../redux/content_slice'
// calls
import { axiosRequest } from '../../../http'
// ** component
import ContentCard from '../../../components/contentCard'

// ** Custom Components
import Toaster from "../../../components/toaster"
import toast from 'react-hot-toast'
import Loader from '../../../components/loader'

const ContentView = () => {
  const [loading, setLoading] = useState(false)
  const {id} = useParams()
  const dispatch = useDispatch()
  const store = useSelector(state => state.content_slice)
  const pending_content = sessionStorage.getItem("pending_content")

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("pending_content")
    }
  }, [])

  const handleGetSingleContent = async () => {
    try {
      const data = {
        content_id: id
      }
      const response = await axiosRequest({sub_url: '/getcontent', data})
      if (response.status === 200) {
        dispatch(
          selectData({
            data: response.data.data,
            contents: []
          })
        )
        setLoading(false)
        return
      }

    } catch (err) {
      setLoading(false)
      return console.log(err)
    }
  }

  useEffect(() => {
    if (Object.keys(store.selectedData).length === 0 || store.selectedData?.id !== id) {
      setLoading(true)
      handleGetSingleContent()
    }
  }, [])


  const handleAction = async (contentInfo) => {
    const admin_token = sessionStorage.getItem('auth_token')
    const data = {}
    let sub_url 
    if (contentInfo.source_type === 1) {
      sub_url = '/login'
      data.email = contentInfo.email
      data.source_type = 1
    } else {
      sub_url = '/sociallogin'
      data.source_id = contentInfo.source_id
      data.source_type = contentInfo.source_type
      data.device_type = 0
    }

      try {
        const response = await axiosRequest({sub_url, data})
        const url = `editcontent/${contentInfo.slug}/?type=0`
        if (response.status === 200) {
          window.open(`${process.env.REACT_APP_WEB_URL}?t=${response.data.body.token}&u=${response.data.body.logged_in_by}&at=${admin_token}&url=${url}`, '_blank', 'noopener,noreferrer')
        }
      } catch (err) {
        return toast.error(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
      }

  }


  return loading ? (<Loader/>) : (Object.keys(store?.selectedData).length === 0 && !loading) ? (
    <Alert color='danger'>
      <h4 className='alert-heading'>Contents not found</h4>
      <div className='alert-body'>
        Contents with id: {id} doesn't exist. Check list of all Contents: <Link to={`${process.env.REACT_APP_URL_PREFIX}/contents`}>Contents List</Link>
      </div>
    </Alert>
  ) : (store?.selectedData !== null || store?.selectedData !== undefined || Object.keys(store?.selectedData).length > 0) && !store.loading && (
    <Fragment>

      {/* Header Section */}
      <Row className='mb-2'>
        <Col sm={6}>
          <h3>Content Details <Badge className='ms-2 f-12' color='success'>{store?.selectedData.content_type === 1 ? 'Comic' : 'Art'}</Badge></h3>
        </Col>
        <Col sm={6} className="text-align-right">
          <Button color='success' className='ms-auto' onClick={() => handleAction(store.selectedData)}>Login As {store.selectedData?.user_name !== "" ? store.selectedData?.user_name : `${store.selectedData?.first_name} ${store.selectedData?.last_name}`} </Button>
        </Col>
      </Row>
      {/* Header Section End */}

      <ContentCard data={store.selectedData} type="content" pending_content={pending_content}/>
    </Fragment>
  ) 
}

export default ContentView