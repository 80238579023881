// ** React Imports
import { Fragment, useState, useEffect, memo, useContext } from 'react'
import { kFormatter } from '@utils'

// **Sidebar
import Pay_user from './Pay_user'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'

// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown, Eye, EyeOff, MessageSquare } from 'react-feather'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import { useRTL } from '@hooks/useRTL'
import { ThemeColors } from '@src/utility/context/ThemeColors'
//custom component
import Loader from '../../components/loader'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, Input, Label, Row, Col, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'
import { getPayouts, selectPayout, setDateFilter } from '../../redux/payout_slice'
import ApexBarChart from '../profit/charts/ApexBarChart'
import { getHomeData } from '../../redux/home_slice'
import { axiosRequest } from '../../http'
import { exportToCsvPayout, exportToPdfpayouts } from '../../utility/pdfAndCsv'
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
const label_data = [
  {
    id: 1,
    label: "Weekly"
  },
  {
    id: 2,
    label: "Monthly"
  },
  {
    id: 3,
    label: "Quarterly"
  },
  {
    id: 4,
    label: "Annually"
  },
  {
    id: 5,
    label: "All Time"
  }
]

const list_data = [
  {
    id: 0,
    label: "All",
    value: "all"
  },
  {
    id: 1,
    label: "Approved",
    value: "1"
  },
  {
    id: 2,
    label: "Pending",
    value: "0"
  }

]


const PayoutsDashboard = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.payoutReducer)
  const home_data = useSelector(state => state.homeSlice)
  const { date_filter, total } = store
  // ** States
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  // const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('created_at')
  const [sortDirection, setSortDirection] = useState('desc')
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [filterValue, setFilterValue] = useState(0)
  const [tableValue, setTableValue] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [isRtl] = useRTL()
  const { colors } = useContext(ThemeColors)

  // ** Function to toggle sidebar
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)


  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getPayouts({
        url: "payoutdashboard",
        page: currentPage,
        perPage: rowsPerPage,
        // q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection,
        startDate,
        endDate
      })
    )
    dispatch(getHomeData())
  }, [dispatch])

  // ** function to handle sorting
  const handleSort = (column, sortDirection) => {
    dispatch(
      getPayouts({
        url: "payoutdashboard",
        page: 1,
        perPage: rowsPerPage,
        // q: searchValue,
        sColumn: column.selector,
        sDirection: sortDirection,
        startDate,
        endDate
      })
    )
    setSortColumn(column.selector)
    setSortDirection(sortDirection)
  }

  // ** Function to handle filter
  // const handleFilter = e => {
  //   setSearchValue(e.target.value)

  //   dispatch(
  //     getPayouts({
  //       url: "payoutdashboard",
  //       page: currentPage,
  //       perPage: rowsPerPage,
  //       // q: e.target.value,
  //       sColumn: sortColumn,
  //       sDirection: sortDirection
  //     })
  //   )
  // }

  const handleFilter = e => {
    setEndDate(null)
    setStartDate(null)
    dispatch(setDateFilter({
      type: "payout",
      status: false,
      data: {
      }
    }))

    dispatch(
      getPayouts({
        page: 1,
        url: "payoutdashboard",
        perPage: rowsPerPage,
        filter: e === "5" ? "4" : e,
        tableFilter: list_data[tableValue].value,
        sColumn: sortColumn,
        sDirection: sortDirection,
        startDate,
        endDate
      })
    )
  }

  // ** Function to handle Pagination and get data
  const handlePagination = page => {
    dispatch(
      getPayouts({
        url: "payoutdashboard",
        page: page.selected + 1,
        perPage: rowsPerPage,
        filter: filterValue,
        dataFilter: list_data[tableValue].value,
        // q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection,
        startDate,
        endDate
      })
    )
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle per page
  const handlePerPage = e => {
    dispatch(
      getPayouts({
        url: "payoutdashboard",
        page: 1,
        perPage: parseInt(e.target.value),
        filter: filterValue,
        dataFilter: list_data[tableValue].value,
        // q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection,
        startDate,
        endDate
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }

  const handleView = (data, index) => {
    dispatch(
      selectPayout({
        data,
        index
      })
    )
  }

  const handeltabelfilter = (number) => {

    dispatch(
      getPayouts({
        page: 1,
        url: "payoutdashboard",
        perPage: rowsPerPage,
        filter: filterValue,
        dataFilter: list_data[number].value,
        // q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection,
        startDate,
        endDate
      })
    )
  }

  // ** Table Server Side Column
  const TableColumns = [
    {
      sortable: true,
      name: 'S.no',
      maxWidth: '40px',
      cell: (row, index) => <p>{((currentPage - 1) * rowsPerPage) + (index + 1)} </p>
    },
    {
      sortable: true,
      name: 'name',
      minWidth: '150px',
      selector: 'fullname'
    },
    {
      sortable: true,
      name: 'Paypal email',
      minWidth: '300px',
      selector: 'paypal_id'
    },
    {
      sortable: true,
      name: 'Transaction Id',
      minWidth: '150px',
      selector: 'transaction_id'
    },
    {
      sortable: true,
      name: 'Amount',
      minWidth: '100px',
      selector: 'amount',
      cell: row => <p className='cursor-pointerp'> {row.amount} USD</p>
    },
    {
      sortable: true,
      name: 'Status',
      minWidth: '100px',
      selector: 'status',
      cell: row => <Badge className='cursor-pointerp' color={row.status === "0" ? 'info' : 'success'}> {row.status === "0" ? "Pending" : "Approved"}</Badge>
    },
    {
      sortable: true,
      name: 'Date',
      minWidth: '150px',
      selector: 'created_at',
      cell: row => <p className='cursor-pointerp'> {moment(row.created_at).utcOffset(0).format('MM/DD/YY hh:mm a')} </p>
    },
    {
      sortable: true,
      name: 'Action',
      minWidth: '150px',
      selector: (row, index) => <Eye className='cursorPointer' onClick={() => {
        handleView(row, index)
        toggleSidebar()
      }} size={18} />
    }
  ]

  const handleDownload = async (type) => {
    try {
      const response = await axiosRequest({
        sub_url: '/admin/getpayouts',
        params: {
          url: "payoutdashboard",
          page: 1,
          perPage: "all",
          filter: filterValue,
          dataFilter: list_data[tableValue].value,
          sColumn: "created_at",
          sDirection: "desc",
          startDate,
          endDate
        }
      })
      const data_pdf = response.data?.data
      if (type === 1) exportToPdfpayouts(data_pdf, list_data[tableValue].label, {
        status: Number(filterValue) === 6,
        date: `${moment(startDate).format('MM-DD-YYYY')} to ${moment(endDate).format('MM-DD-YYYY')}`
      })
      else if (type === 2) exportToCsvPayout(data_pdf, list_data[tableValue].label)
    } catch (err) {
      if (!err.response) {
      }
    }
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(store.total / rowsPerPage)

    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel='...'
        pageCount={Math.ceil(count) || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePagination(page)}
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName={
          'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
        }
      />
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    // const filters = {
    //   q: searchValue
    // }

    // const isFiltered = Object.keys(filters).some(function (k) {
    //   return filters[k].length > 0
    // })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && !store.loading) {
      return []
    } else {
      if (store.allData.data && store.allData.data.length > 0) {
        return store.allData.data.slice(0, rowsPerPage)
      }
    }
  }

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setFilterValue(6)
      dispatch(
        getPayouts({
          page: 1,
          url: "payoutdashboard",
          perPage: rowsPerPage,
          filter: "6",
          tableFilter: list_data[tableValue].value,
          sColumn: sortColumn,
          sDirection: sortDirection,
          startDate,
          endDate
        })
      )

      dispatch(setDateFilter({
        type: "payout",
        status: true,
        data: {
          startDate,
          endDate
        }
      }))
    }
  }, [endDate, startDate])


  return (
    <Fragment>
      <Card>
        <CardHeader className='border-bottom'>
          <CardTitle tag='h4'>Payouts:  ${kFormatter(parseFloat((home_data.data?.Financial?.totalPayout ?? 0) + (home_data.data?.Financial?.totalpendingPayout ?? 0)).toFixed(2))}</CardTitle>
        </CardHeader>
        <Row className='mx-0 mt-1 mb-50'>
          <Row className='mx-0 mt-1 mb-50'>
            <Col sm={6} className="d-flex align-items-center flex-sm-row flex-column ">

              <div className="d-flex align-items-center">
                <ReactDatePicker
                  peekNextMonth
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => {
                    if (date) {
                      const formattedDate = moment(date, 'YYYY-MM-DD', true).isValid() ? moment(date).format('YYYY-MM-DD') : null


                      setStartDate(formattedDate)
                    } else {
                      setStartDate(null)
                    }
                  }}
                  selected={startDate ? moment(startDate).toDate() : null}
                  placeholderText="From"
                  className={`form-control`}
                />
              </div>
              <div className="d-flex align-items-center date_border mt-sm-0 mt-2 margin_date">
                <ReactDatePicker
                  peekNextMonth
                  dropdownMode="select"
                  showMonthDropdown
                  showYearDropdown
                  onChange={(date) => {
                    if (date) {
                      const formattedDate = moment(date, 'YYYY-MM-DD', true).isValid() ? moment(date).format('YYYY-MM-DD') : null

                      setEndDate(formattedDate)
                    } else {
                      setEndDate(null)
                    }
                  }}
                  selected={endDate ? moment(endDate).toDate() : null}
                  placeholderText="To"
                  className={`form-control`}
                />
              </div>
            </Col>
            <Col className='d-flex flex-column align-items-end' sm='6'>
              <div className='d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1'>
                <UncontrolledDropdown className='chart-dropdown ml-3'>
                  <DropdownToggle color='' className='bg-transparent btn-sm border-0 p-50 text_color'>
                    {!filterValue || filterValue === 6 ? 'Quarterly' : label_data[filterValue - 1]?.label}
                  </DropdownToggle>
                  <DropdownMenu end>
                    {label_data.map((item) => (
                      <DropdownItem
                        key={item.id}
                        onClick={() => { setFilterValue(item.id.toString()); handleFilter(item.id.toString()) }}
                        className='w-100 text_color'
                      >
                        {item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>

                {filterValue ? <Button onClick={() => {
                  setFilterValue(0)
                  setTableValue(0)
                  dispatch(getPayouts({
                    page: 1,
                    url: "payoutdashboard",
                    perPage: rowsPerPage,
                    filter: 0,
                    dataFilter: list_data[0].value
                  }))

                  setEndDate(null)
                  setStartDate(null)
                  dispatch(setDateFilter({
                    type: "payout",
                    status: false,
                    data: {
                    }
                  }))
                }} className='mr-3'>
                  Clear
                </Button> : ""}
              </div>
              <strong style={{ color: "#40c07b" }}>*For now, all values are from inception</strong>
            </Col>
          </Row>
          <Col sm={12}>
            <ApexBarChart direction={isRtl ? 'rtl' : 'ltr'} info={colors.info.main} title="Payout" data={store.barGraph} filterValue={Number(filterValue)} date_filter={{ ...date_filter, count: total }} />
          </Col>
          <Col sm='12' md={6} className='d-flex justify-content-start align-items-center mt-2 mt-sm-0 main_export_div' >
            <div className='d-flex align-items-center'>
              <Label for='sort-select'>show</Label>
              <Input
                className='dataTable-select'
                type='select'
                id='sort-select'
                value={rowsPerPage}
                onChange={e => handlePerPage(e)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
              <Label for='sort-select'>entries</Label>
            </div>


          </Col>
          <Col sm={12} md={6} className='d-flex justify-content-end align-items-center mt-2 mt-sm-0 main_export_div'>

            <div className='main_button_div d-flex'>
              <Button onClick={() => {
                handleDownload(2)
              }} className='' style={{
                marginRight: "15px"
              }}>
                Export CSV
              </Button>
              <Button onClick={() => {
                handleDownload(1)
              }} className='' style={{
                marginRight: "15px"
              }}>
                Export PDF
              </Button>
            </div>

            <UncontrolledDropdown className='chart-dropdown ml-3'>
              <DropdownToggle color='' className='bg-transparent btn-sm border-0 p-50 text_color'>
                {!tableValue ? 'All' : list_data[tableValue]?.label}
              </DropdownToggle>
              <DropdownMenu end>
                {list_data.map((item) => (
                  <DropdownItem
                    key={item.id}
                    onClick={() => { setTableValue(item.id); handeltabelfilter(item.id) }}
                    className='w-100 text_color'
                  >
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
          {/* <Col className='d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1' sm='6'>
            <Label className='me-1' for='search-input'>
              Search
            </Label>
            <Input
              className='dataTable-filter'
              type='text'
              bsSize='sm'
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </Col> */}
        </Row>
        {store.loading && store.data.length === 0 ? <Loader /> : <div className='react-dataTable'>
          <DataTable
            noHeader
            pagination
            paginationServer
            className='react-dataTable'
            onSort={handleSort}
            columns={TableColumns}
            sortIcon={<ChevronDown size={10} />}
            paginationComponent={CustomPagination}
            data={dataToRender()}
          />
        </div>}
      </Card>
      <Pay_user open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </Fragment>
  )
}

export default memo(PayoutsDashboard)
