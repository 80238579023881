// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getSubscriptionList = createAsyncThunk(
  'GET_SUBSCRIPTION_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getallsubscriptions', params})
      if (response.status === 200) {
        return { allData: response.data, data: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
})

export const getSubscribersList = createAsyncThunk(
  'GET_SUBSCRIBERS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getallsubscribers', params, data: {subscription_id: params.subscription_id}})
      if (response.status === 200) {
        return { allData: response.data, data: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const SubscriptionSlice = createSlice({
  name: 'SUBSCRIPTIONLIST',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false
  },
  reducers: {},
  extraReducers: {
    [getSubscriptionList.pending] : (state) => {
      state.loading = true
      state.data = []
    },
    [getSubscriptionList.rejected] : (state) => {
      state.loading = false
    },
    [getSubscriptionList.fulfilled] : (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    },
    [getSubscribersList.pending] : (state) => {
      state.loading = true
      state.data = []
    },
    [getSubscribersList.rejected] : (state) => {
      state.loading = false
    },
    [getSubscribersList.fulfilled] : (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    }
  }
})

export default SubscriptionSlice.reducer
