import React, { useRef, useEffect } from 'react'
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import { Calendar } from 'react-feather'
import { Bar } from 'react-chartjs-2'
import Chart from 'chart.js/auto'
import '@styles/react/libs/flatpickr/flatpickr.scss'
const ChartjsBarChart = ({ success, gridLineColor, labelColor }) => {
  const canvasRef = useRef(null)
  const chartRef = useRef(null)

  useEffect(() => {
    const renderChart = () => {
      if (!canvasRef.current || !chartRef.current) return

      chartRef.current.destroy()

      const ctx = canvasRef.current.getContext('2d')

      const data = {
        labels: [
          '7/12',
          '8/12',
          '9/12',
          '10/12',
          '11/12',
          '12/12',
          '13/12',
          '14/12',
          '15/12',
          '16/12',
          '17/12',
          '18/12',
          '19/12'
        ],
        datasets: [
          {
            maxBarThickness: 15,
            backgroundColor: success,
            borderColor: 'transparent',
            borderRadius: { topRight: 15, topLeft: 15 },
            data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 230, 280, 190]
          }
        ]
      }

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        animation: { duration: 500 },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'M/D'
              }
            },
            grid: {
              color: gridLineColor,
              borderColor: gridLineColor
            },
            ticks: { color: labelColor }
          },
          y: {
            min: 0,
            max: 400,
            grid: {
              color: gridLineColor,
              borderColor: gridLineColor
            },
            ticks: {
              stepSize: 100,
              color: labelColor
            }
          }
        },
        plugins: {
          legend: { display: false }
        }
      }

      chartRef.current = new Chart(ctx, {
        type: 'bar',
        data,
        options
      })
    }

    renderChart()

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
    }
  }, [success, gridLineColor, labelColor])

  return (
    <Card>
      <CardHeader className='d-flex justify-content-between align-items-sm-center align-items-start flex-sm-row flex-column'>
        <CardTitle tag='h4'>Latest Statistics</CardTitle>
        <div className='d-flex align-items-center'>
          <Calendar size={14} />
          <input
            className='form-control flat-picker bg-transparent border-0 shadow-none'
            type='text'
            readOnly
            defaultValue={`Selected Range`}
          />
        </div>
      </CardHeader>
      <CardBody>
        <div style={{ height: '400px' }}>
          <canvas ref={canvasRef} />
        </div>
      </CardBody>
    </Card>
  )
}

export default ChartjsBarChart
