// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getSettings = createAsyncThunk(
  'GET_SETTING_DATA', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getcreditsettings', params})
      if (response.status === 200) {
        return { data: response.data.data}
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response)
  }
  
})

export const getpromotionpackage = createAsyncThunk(
  'GET_PROMOTION_DATA', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getpromotionPackage', params})
      if (response.status === 200) {
        return { data: response.data.data}
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response)
  }
  
})

export const setNewSettings = createAsyncThunk(
  'SET_SETTINGS_DATA', 
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/creditsettings', data})
      return response
    } catch (err) {
      if (err.response.data.logout) {
          localStorage.clear()
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response)
  }
  
})

export const setNewPromotion = createAsyncThunk(
  'SET_PROMOTION_DATA', 
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/updatepromotionpackage', data})
      return {...response.data}
    } catch (err) {
      if (err.response.data.logout) {
          localStorage.clear()
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response)
  }
  
})

export const createCreditPlans = createAsyncThunk(
  'CREATE_CREDIT_PLANS', 
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/createCreditPlans', data})
      return {response, data}
    } catch (err) {
      // if (err.response.data.logout) {
      //     localStorage.clear()
      //     sessionStorage.clear()
      // }  
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response)
  }
  
})

export const updateCreditPlans = createAsyncThunk(
  'UPDATE_CREDIT_PLANS', 
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/updateCredit', data})
      return response
    } catch (err) {
      // if (err.response.data.logout) {
      //     localStorage.clear()
      //     sessionStorage.clear()
      // }  
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response)
  }
  
})

export const SettingSlice = createSlice({
  name: 'SETTINGS',
  initialState: {
    data: [],
    promotion_package:[],
    toastDetails: {
      toast_status: null,
      status_code: null,
      toast_message: null
    },
    set_credit_settings: null,
    edit_credit_settings: {
     status: false,
     data: {}
    },
    set_credit_status:null,
    loading: false
  },
  reducers: {
    resetMessage: (state) => {
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    setCreditSettings: (state, action) => {
      state.set_credit_settings = action.payload
    },
    editCreditPackage: (state, action) => {
      state.edit_credit_settings = {
        status: action.payload.status,
        data: action.payload.data
      }
    },
    setCreditStatus: (state, action) => {
      state.set_credit_status = action.payload
    }

  },
  extraReducers: {
    [getSettings.pending]: (state) => {
      state.loading = true
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    [getSettings.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.toast_status = true
      state.toastDetails.status_code = action.payload.status
    },
    [getSettings.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload.data
    },
    [setNewSettings.pending]: (state) => {
      state.loading = true
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    [setNewSettings.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
    },
    [setNewSettings.fulfilled]: (state, action) => {
      
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
      state.set_credit_settings = true
      
    },
    [createCreditPlans.pending]: (state) => {
      state.loading = true
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    [createCreditPlans.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
      state.set_credit_status = false
    },
    [createCreditPlans.fulfilled]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
      state.set_credit_status = true

    },
    [updateCreditPlans.pending]: (state) => {
      state.loading = true
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    [updateCreditPlans.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
      state.set_credit_status = false
    },
    [updateCreditPlans.fulfilled]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
      state.set_credit_status = true

    },
    [getpromotionpackage.pending]: (state) => {
      state.loading = true
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    [getpromotionpackage.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.data.message
      state.toastDetails.status_code = action.payload.status
    },
    [getpromotionpackage.fulfilled]: (state, action) => {
      state.loading = false
      // state.toastDetails.toast_status = true
      // state.toastDetails.toast_message = action.payload.data.message
      // state.toastDetails.status_code = action.payload.status
      state.promotion_package = action.payload.data
    },
    [setNewPromotion.pending]: (state) => {
      state.loading = true
      state.toastDetails.toast_status = null
      state.toastDetails.status_code = null
      state.toastDetails.toast_message = null
    },
    [setNewPromotion.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.message
      state.toastDetails.status_code = action.payload.status
    },
    [setNewPromotion.fulfilled]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_status = true
      state.toastDetails.toast_message = action.payload.message
      state.toastDetails.status_code = action.payload.status
    }

  }
})

export const {resetMessage, setCreditSettings, editCreditPackage, setCreditStatus} = SettingSlice.actions
export default SettingSlice.reducer
