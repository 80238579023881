// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getPayouts = createAsyncThunk(
    'GET_PAYOUTS_LIST',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axiosRequest({ sub_url: '/admin/getpayouts', params })
            if (response.status === 200) {
                return { allData: response.data, response: response.data, totalPages: response.data.count, params }
            }
        } catch (err) {
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }

    })

export const payout_slice = createSlice({
    name: 'PAYOUTS',
    initialState: {
        data: [],
        selected_payout: {},
        allData: [],
        barGraph: [],
        params: {},
        toastDetails: {
            toast_status: null,
            status_code: null,
            toast_message: null
        },
        date_filter: {
            status: false,
            data: {}
        },
        total: 1
    },
    reducers: {
        selectPayout(state, { payload }) {
            state.selected_payout = payload
        },
        updatePayoutStatus(state, { payload }) {
            state.data = state.data.map(item => {
                if (item.id === payload?.id) {
                    return {
                        ...item,
                        status: "1",
                        transaction_id: payload.transaction_id
                    }
                }
                return item
            })
        },
        setDateFilter: (state, { payload }) => {
            switch (payload.type) {
                case "payout":
                    state.date_filter.status = payload.status
                    state.date_filter.data = payload.data
                    break
            }
        }

    },
    extraReducers: {
        [getPayouts.pending]: (state) => {
            state.loading = true
            state.toastDetails.toast_status = null
            state.toastDetails.status_code = null
            state.toastDetails.toast_message = null
        },
        [getPayouts.rejected]: (state, action) => {
            state.loading = false
            state.toastDetails.toast_message = action.payload
            state.toastDetails.toast_status = true
            state.barGraph = action.payload?.response?.barGraph || []
            state.toastDetails.status_code = action.payload
            state.allData = action?.payload?.response?.allData || []
        },
        [getPayouts.fulfilled]: (state, action) => {
            state.loading = false
            state.data = action.payload.response.data
            state.barGraph = action.payload.response.barGraph
            state.allData = action.payload.response.allData || []
            state.total = action.payload.response.count
            state.params = action.payload.params
        }
    }
})

export const { selectPayout, updatePayoutStatus, setDateFilter } = payout_slice.actions
export default payout_slice.reducer
