import React, { Fragment, useEffect, useState } from 'react'
// third party
import { Card, Row, Col, Badge, Button, Input } from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import BuyersList from '../buyersList'
import ReaderList from '../readerList'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

//custom component
import Model from '../model'
import { axiosRequest } from '../../http'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { approveModal, buyesTable, readersTable, selectData_update, updateContentStatus, updateContentpenStatus } from '../../redux/content_slice'
import Toaster from "../../components/toaster"
import toast from 'react-hot-toast'
import ApproveContentModal from '../modals/ApproveContentModal'
const ContentCard = (props) => {
  const dispatch = useDispatch()
  const { pending_content } = props
  const navigate = useNavigate()

  const params = useParams()
  const store = useSelector(state => state.content_slice)
  const MySwal = withReactContent(Swal)
  const [futrue_check, set_futrue_check] = useState(false)
  const handleBuyersList = (count) => {
    if (count === 0) {
      return toast.error(<Toaster message="Content not bought by anyone yet" />, { hideProgressBar: true })
    }

    dispatch(
      buyesTable({
        show: true,
        index: 0
      })
    )
  }


  const handleReaderList = (count) => {
    if (count === 0) {
      return toast.error(<Toaster message="Content not bought by anyone yet" />, { hideProgressBar: true })
    }

    dispatch(
      readersTable({
        show: true,
        index: 0
      })
    )
  }
  const handleStatusClick = (seriesData, colData) => {
    if (colData?.series_id === 0) {
      return MySwal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${colData.status === 1 ? "Deactivate" : "Activate"}  Content!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ms-1'
        },
        buttonsStyling: false
      }).then(async function (result) {
        if (result.value) {
          try {
            const response = await axiosRequest({
              sub_url: '/admin/status_config',
              data: {
                user_id: params.id,
                status: colData.status === 1 ? "0" : "1" || colData.status === 2 ? "1" : "2",
                content_id: colData.id
              }
            })

            if (response.status === 200) {
              dispatch(
                updateContentStatus({
                  status: colData.status === 1 ? 0 : 1 || colData.status === 2 ? 1 : 2

                })
              )
              return toast.success(<Toaster message={response?.data?.message} />, { hideProgressBar: true })

            }

          } catch (err) {
            return toast.success(<Toaster message={err?.response?.data?.message} />, { hideProgressBar: true })
          }
        }
      })
    } else {
      return MySwal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, ${colData.status === 1 ? "Inactive" : "Active"}  Series!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ms-1'
        },
        buttonsStyling: false
      }).then(async function (result) {
        if (result.value) {
          try {
            const response = await axiosRequest({
              sub_url: '/admin/status_config',
              data: {
                user_id: params.id,
                status: colData.status === 1 ? "0" : "1" || colData.status === 2 ? "1" : "2",
                series_id: colData.id
              }
            })

            if (response.status === 200) {
              dispatch(
                updateContentStatus({
                  status: colData.status === 1 ? 0 : 1 || colData.status === 2 ? 1 : 2,
                  restricted: [3, 4]
                })
              )
              return toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
            }

          } catch (err) {
            return toast.success(<Toaster message={err?.response?.data?.message} />, { hideProgressBar: true })
          }
        }
      })
    }
  }
  const handleAction = async (contentInfo, propsData) => {
    const admin_token = sessionStorage.getItem('auth_token')
    
    const data = {}
    let sub_url
    if (contentInfo.source_type === 1) {
      sub_url = '/login'
      data.email = contentInfo.email
      data.source_type = 1
    } else {
      sub_url = '/sociallogin'
      data.source_id = contentInfo.source_id
      data.source_type = contentInfo.source_type
      data.device_type = 0
    }

    try {
      const response = await axiosRequest({ sub_url, data })
      const url = propsData.series_id === undefined ? `series/detail/${contentInfo.slug}/?type=0` : `content/view/${contentInfo.slug}/?type=0`

      if (response.status === 200) {
        window.open(`${process.env.REACT_APP_WEB_URL}?t=${response.data.body.token}&u=${response.data.body.logged_in_by}&at=${admin_token}&url=${url}`, '_blank', 'noopener,noreferrer')
      }
    } catch (err) {
      return toast.error(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
    }

  }

  useEffect(() => {
    if (props.data) {
      set_futrue_check(props?.data?.future_content)
    }
  }, [props.data])

  const handleCheck_box = () => {
    return MySwal.fire({
      title: `${futrue_check ? `Are you sure you would like to remove this ${props?.data?.type} from featured content?` : `Are you sure you would like to add this ${props?.data?.type} in featured content?`}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes`,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1',
        title: "h3"
      },
      buttonsStyling: false
    }).then(async function (result) {
      if (result?.value) {
        try {
          const obj_data = {
            content_id: props?.data?.id,
            future_content: futrue_check ? 0 : 1,
            type: props?.data?.type
          }
          const sub_url = '/admin/makefuturecontent'

          const response = await axiosRequest({ sub_url, data: { ...obj_data } })


          if (response.data.status) {
            dispatch(selectData_update({
              key_name: "future_content",
              key_value: futrue_check ? 0 : 1
            }))
            set_futrue_check(!futrue_check)
            return toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
          } else {
            return toast.error(<Toaster message={response.data.message} />, { hideProgressBar: true })
          }
        } catch (err) {
          return toast.error(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
        }
      }

    })
  }

  const buttonText = props?.data?.series_id === undefined ? "View Details" : "view Content"


  const handleaproveClick = async (seriesData, colData, type) => {
console.log({colData})
    return dispatch(approveModal({
      show: true,
      data: {
        type: pending_content !== "reject" ? props?.data?.series_id !== undefined ? "single" : "series" : "content",
        status: type === "approved" ? "approved" : type === "revert" ? "revert" : "reject",
        ...(props?.data?.series_id !== undefined  ? { content_id: colData.id } : { series_id: colData?.id, whole_series: true })
      }
    }))

    return MySwal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${type === "approved" ? "Approve" : "Reject"}  Content!`,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(async function (result) {
      if (result.value) {
        try {
          const response = await axiosRequest({
            sub_url: '/admin/approvecontent',
            data: {
              status: type,
              content_id: colData.id
            }
          })

          if (response.status === 200) {
            dispatch(
              updateContentpenStatus({
                status: type === "approved" ? 1 : 4
              })
            )
            toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
            navigate(`${process.env.REACT_APP_URL_PREFIX}/pendingcontent`)
          }

        } catch (err) {
          return toast.success(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
        }
      }
    })
  }

  return (
    <Fragment>
      <Card className='p-2'>
        <Row style={{ height: '431px' }}>
          <Col sm={3} xs={12} >
            <div className='coverImage' style={{ height: '100%' }} >
              <img src={props.data.cover_image} alt={props.data.title} style={{ height: '100%', maxHeight: '400px' }} />
            </div>
          </Col>
          <Col sm={9} xs={12}>
            <Row className='contentDetails'>
              <Col sm={12} className='contentTitle'>
                <div className='CardHeader d-flex justify-content-space-between'>
                  <h3 className='d-inline'>{props.data.title} </h3>
                </div>
                <hr className='mt-1' />
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Genre : </h6> <span>{props.data.genre_name}</span>
              </Col>


              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Sub Genre : </h6> <span>{props.data.sub_genres.length > 0 && props.data.sub_genres.map(item => item.genre)}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Paid / Free : </h6> <span>{props.data.is_paid === 0 ? "Free" : "Paid"}</span>
              </Col>

              {props.data.is_paid === 1 &&
                <Col sm={6} className='contentTitle mt-25'>
                  <h6 className='d-inline'>Price : </h6> <span>{props.data.price}</span>
                </Col>}

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Author : </h6> <span>{props.data.user_name !== "" ? props.data.user_name : `${props.data.first_name} ${props.data.last_name}`}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Downloadable : </h6> <span>{props.data.is_downloadable === 1 || props.data.is_downloadable === "1" ? "Yes" : "No"}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Available for subscribers : </h6> <span>{props.data.is_available === 1 || props.data.is_available === "1" ? "Yes" : "No"}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Total Favorites : </h6> <span>{props.data.favourite_count}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Total Comments : </h6> <span>{props.data.total_comments}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Total Ratings : </h6> <span>{props.data.total_rating}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Matured Content : </h6> <span>{props.data.is_mature === 1 ? "Yes" : "No"}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Status : </h6> <Badge className='ms-1' color={props.data.status === 1 ? 'success' : 'danger'}>{props.data.status === 1 ? 'Active' : 'Inactive'}</Badge>
              </Col>
              {props.type === "content" &&
                <Col sm={12}>
                  <Row>
                    <Col sm={6} className='contentTitle  mt-25'>
                      <div className='d-flex justify-content-between main_info_div'>
                        <h6 className='d-inline mb-0'>Total Buyers :  {props.data.buyers}</h6>
                        <Badge className='ms-1 view_badge' color={'info'} onClick={() => handleBuyersList(props.data.buyers)}>{'View details >>'}</Badge>
                      </div>
                    </Col>

                    <Col sm={6} className='contentTitle mt-25'>
                      <div className='d-flex justify-content-between main_info_div'>
                        <h6 className='d-inline mb-0'>No. of Readers :  {props.data.visiters}</h6>
                        <Badge className='ms-1 view_badge' color={'info'} onClick={() => handleReaderList(props.data.visiters)}>{'View details >>'}</Badge>
                      </div>
                    </Col>
                  </Row>
                </Col>}

              {(pending_content === "true" || pending_content === "reject") ? "" : <Col sm={12} className='mt-50 cursor-pointer'>
                <Input type='checkbox'
                  className='checbox_input'
                  onClick={() => {
                    handleCheck_box()
                  }}
                  checked={futrue_check ? "cheched" : ""} />
                <h6 className='d-inline check_box_future' onClick={() => {
                  handleCheck_box()
                }}>Featured Content</h6>
              </Col>}
            </Row>
            {pending_content === "true" ? <Row style={{ marginTop: '10px' }}>

              <Col sm={12}>
                <>
                  <Button className='ms-auto m-25' color={'success'} outline onClick={() => handleaproveClick(store.seriesContent, props.data, "approved")}>
                    Approve
                  </Button>
                  <Button className='ms-auto m-25' color={'danger'} outline onClick={() => handleaproveClick(store.seriesContent, props.data, "reject")}>
                    Disapprove
                  </Button>
                </>
              </Col>
            </Row> : ""}
            <Row style={{ marginTop: '10px' }}>
              <Col sm={12}>
                {(pending_content === "true" || pending_content === "reject") ? "" : <Button className='ms-auto m-25' color={props.data.status === 1 ? 'danger' : 'success'} outline onClick={() => handleStatusClick(store.seriesContent, props.data)}>
                  {props.data.status === 1 ? "Deactivate" : "Activate"}
                </Button>}
                {pending_content !== "true" ? <Button color={pending_content === "reject" ? 'success' : "info"} className='ms-auto m-25' onClick={() => handleaproveClick(store.seriesContent, props.data, "revert")} >
                  {pending_content === "reject" ? "Revert Status" : "Make Pending"}
                </Button> : ""}
                {pending_content === "reject" ? "" : <Button color='success' className='ms-auto' onClick={() => handleAction(store.selectedData, props.data)}>{buttonText}
                </Button>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {(props.type === "content" && store.contentBuyers.model.show) &&
        <Model>
          <BuyersList data={props.data} store={store.contentBuyers} />
        </Model>
      }

      {(props.type === "content" && store.contentReaders.model.show) &&
        <Model>
          <ReaderList data={props.data} store={store.contentReaders} />
        </Model>
      }

      <ApproveContentModal />

    </Fragment>
  )
}

export default ContentCard