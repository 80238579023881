// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getCreditPlanList = createAsyncThunk(
  'GET_CREDITPLAN_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/credit-plans', params})
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          localStorage.clear()
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getCreditPlanUsersList = createAsyncThunk(
  'GET_CREDITPLAN_USERS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/credit-plans-users', params, data: {plan_id :params.plan_id}})
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          localStorage.clear()
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const CreditSlice = createSlice({
  name: 'CREDITPLANS',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false,
    err: false,
    err_message: null
  },
  reducers: {
    updatePlanStatus: (state, action) => {
      state.data.forEach(item => {
        if (item.id === action.payload.plan_id) {
          item.status = action.payload.status
        }
      })
    }
  },
  extraReducers: {
    [getCreditPlanList.pending]: (state) => {
      state.loading = true
      state.data = []
    },
    [getCreditPlanList.rejected]: (state) => {
      state.loading = false
    },
    [getCreditPlanList.fulfilled]: (state, action) => {
      state.data = action.payload.response.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.loading = false
    },
    [getCreditPlanUsersList.pending]: (state) => {
      state.loading = true
      state.data = []
    },
    [getCreditPlanUsersList.rejected]: (state, action) => {
      state.loading = false
      state.err = true
      state.err_message = action.payload.message
    },
    [getCreditPlanUsersList.fulfilled]: (state, action) => {
      state.data = action.payload.response.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.loading = false
    }
  }
})
export const {updatePlanStatus} = CreditSlice.actions
export default CreditSlice.reducer
