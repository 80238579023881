// ** React Imports
import { Fragment, useState, useEffect, memo, useContext } from 'react'
import { kFormatter } from '@utils'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown, Eye, EyeOff, MessageSquare } from 'react-feather'
import DataTable from 'react-data-table-component'
import moment from 'moment'

//custom component
import Loader from '../../../components/loader'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, Input, Label, Row, Col, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'

import { useRTL } from '@hooks/useRTL'
import { ThemeColors } from '@src/utility/context/ThemeColors'
// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'
import { emptyRevenue, getRevenueData, setDateFilter } from '../../../redux/home_slice'
import ApexBarChart from '../../profit/charts/ApexBarChart'
import { axiosRequest } from '../../../http'
import { exportToCsv, exportToPdf } from '../../../utility/pdfAndCsv'
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Revenue_sidebar from './revenue_sidebar'
const label_data = [
    {
        id: 1,
        label: "Weekly"
    },
    {
        id: 2,
        label: "Monthly"
    },
    {
        id: 3,
        label: "Quarterly"
    },
    {
        id: 4,
        label: "Annually"
    },
    {
        id: 5,
        label: "All Time"
    }
]

const list_data = [
    {
        id: 0,
        label: "All",
        value: "all"
    },
    // {
    //     id: 1,
    //     label: "Credits Purchase",
    //     value: ["uct"]
    // },
    {
        id: 1,
        label: "Purchase",
        value: ["ct", "st"]
    },
    {
        id: 2,
        label: "Donation",
        value: ["dt"]
    },
    {
        id: 3,
        label: "Promotion",
        value: ["cp", "sp"]
    }
]
const Datatable = () => {

    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.homeSlice.revenue_data)
    const home_data = useSelector(state => state.homeSlice)
    const { date_filter, count, receiverNames } = store

    const [isRtl] = useRTL()
    const { colors } = useContext(ThemeColors)
    // ** States
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState('created_at')
    const [sortDirection, setSortDirection] = useState('desc')
    const [filterValue, setFilterValue] = useState(0)
    const [tableValue, setTableValue] = useState(0)
    const [receiverId, setReceiverId] = useState(0)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [props_data, setPropsData] = useState({})


    const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
    // ** Get data on mount
    useEffect(() => {
        dispatch(
            getRevenueData({
                page: store.page,
                perPage: rowsPerPage,
                sColumn: sortColumn,
                sDirection: sortDirection
            })
        )
    }, [dispatch])

    // ** function to handle sorting
    const handleSort = (column, sortDirection) => {
        dispatch(
            getRevenueData({
                page: store.page,
                perPage: rowsPerPage,
                filter: filterValue,
                sColumn: column.selector,
                sDirection: sortDirection
            })
        )
        setSortColumn(column.selector)
        setSortDirection(sortDirection)
    }

    // ** Function to handle filter
    const handleFilter = e => {
        setEndDate(null)
        setStartDate(null)
        dispatch(setDateFilter({
            type: "revenue",
            status: false,
            data: {}
        }))
        dispatch(
            getRevenueData({
                page: 1,
                perPage: rowsPerPage,
                filter: e === 5 ? 4 : e,
                tableFilter: list_data[tableValue].value,
                beneficiary_condition: receiverId === 0 ? null : receiverNames[receiverId - 1].receiver_name,
                sColumn: sortColumn,
                sDirection: sortDirection
            })
        )
    }

    // ** Function to handle Pagination and get data
    const handlePagination = page => {
        dispatch(
            getRevenueData({
                page: page.selected + 1,
                perPage: rowsPerPage,
                filter: filterValue,
                tableFilter: list_data[tableValue].value,
                beneficiary_condition: receiverId === 0 ? null : receiverNames[receiverId - 1].receiver_name,
                sColumn: sortColumn,
                sDirection: sortDirection,
                startDate,
                endDate
            })
        )
    }

    // ** Function to handle per page
    const handlePerPage = e => {
        dispatch(
            getRevenueData({
                page: 1,
                perPage: parseInt(e.target.value),
                sColumn: sortColumn,
                sDirection: sortDirection,
                startDate,
                endDate
            })
        )
        setRowsPerPage(parseInt(e.target.value))
    }

    // ** Table Server Side Column
    const TableColumns = [
        {
            sortable: true,
            name: 'S.no',
            maxWidth: '40px',
            cell: (row, index) => <p>{((store.page - 1) * rowsPerPage) + (index + 1)} </p>
        },
        {
            sortable: true,
            name: 'name',
            minWidth: '150px',
            selector: 'full_name',
            cell: row => <p className='cursor-pointerp text-capitalize'> {row?.full_name ? row.full_name.trim() : "N/A"}</p>
        },
        {
            sortable: true,
            name: 'Email',
            minWidth: '300px',
            selector: 'email'
        },
        {
            sortable: true,
            name: 'Beneficiary',
            minWidth: '300px',
            selector: "receiver_name",
            cell: row => <p className='cursor-pointerp text-capitalize'> {row?.receiver_name ? row.receiver_name.trim() : "N/A"}</p>
        },
        {
            sortable: true,
            name: 'Amount',
            minWidth: '100px',
            selector: 'amount',
            cell: row => <p className='cursor-pointerp'> {parseFloat(row.amount).toFixed(2)} USD</p>
        },
        {
            sortable: false,
            name: 'Type',
            minWidth: '100px',
            selector: 'status',
            cell: row => <Badge className='cursor-pointerp' color={'info'}>
                {row.source_table}
                {row.source_table.toLowerCase() === 'donation' &&
                    <span> to {row.other_data.donate_to} </span>
                }
            </Badge>
        },
        {
            sortable: true,
            name: 'Date',
            minWidth: '150px',
            selector: 'created_at',
            cell: row => <p className='cursor-pointerp'> {moment(row.created_at).utcOffset(0).format('MM/DD/YY hh:mm a')} </p>
        },
        {
            sortable: false,
            name: 'Action',
            minWidth: '150px',
            center: true,
            selector: row => <Fragment>
                <div className='cursor-pointer' onClick={() => {
                    if (row.source_table) {
                        setPropsData({
                            type: row.source_table,
                            data: row?.other_data
                        })
                    }

                    toggleSidebar()
                }}>
                    <Eye className='cursor-pointerp' style={{ marginRight: "8" }} size={16} />
                </div>
            </Fragment>
        }

    ]


    // ** Custom Pagination
    const CustomPagination = () => {
        const count = Math.ceil(store.count / rowsPerPage)

        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                breakLabel='...'
                pageCount={Math.ceil(count) || 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                activeClassName='active'
                forcePage={store.page !== 0 ? store.page - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName='page-item'
                breakClassName='page-item'
                nextLinkClassName='page-link'
                pageLinkClassName='page-link'
                breakLinkClassName='page-link'
                previousLinkClassName='page-link'
                nextClassName='page-item next-item'
                previousClassName='page-item prev-item'
                containerClassName={
                    'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
                }
            />
        )
    }

    useEffect(() => {

        return () => {
            dispatch(emptyRevenue())
        }
    }, [])

    // ** Table data to render
    const dataToRender = () => {
        if (store.data.length > 0) {
            console.log({data:store.data})

            return store.data
        } else if (store.data.length === 0 && !store.loading) {
            return []
        }
    }
    const handeltabelfilter = (number) => {

        dispatch(
            getRevenueData({
                page: 1,
                perPage: rowsPerPage,
                filter: filterValue,
                tableFilter: list_data[number].value,
                sColumn: sortColumn,
                sDirection: sortDirection,
                startDate,
                endDate,
                beneficiary_condition: receiverId === 0 ? null : receiverNames[receiverId - 1]?.receiver_name
            })
        )
    }

    // const handelReceiverfilter = (number) => {
    //     console.log({ number })
    //     dispatch(
    //         getRevenueData({
    //             page: 1,
    //             perPage: rowsPerPage,
    //             filter: filterValue,
    //             tableFilter: list_data[tableValue].value,
    //             sColumn: sortColumn,
    //             sDirection: sortDirection,
    //             startDate,
    //             endDate,
    //             beneficiary_condition: number === 0 ? null : receiverNames[(number - 1)]?.receiver_name
    //         })
    //     )
    // }


    const handleDownload = async (type) => {
        try {
            const response = await axiosRequest({
                sub_url: '/admin/getrevenuelist',
                params: {
                    page: 1,
                    perPage: "all",
                    filter: filterValue,
                    tableFilter: list_data[tableValue].value,
                    beneficiary_condition: receiverId === 0 ? null : receiverNames[receiverId - 1].receiver_name,
                    sColumn: "created_at",
                    sDirection: "desc",
                    startDate,
                    endDate
                }
            })
            const data_pdf = response.data?.data
            if (type === 1) exportToPdf(data_pdf, list_data[tableValue].label, {
                status: Number(filterValue) === 6,
                date: `${moment(startDate).format('MM-DD-YYYY')} to ${moment(endDate).format('MM-DD-YYYY')}`
            })
            else if (type === 2) exportToCsv(data_pdf, list_data[tableValue].label)
        } catch (err) {
            if (!err.response) {
            }
        }
    }

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            setFilterValue(6)
            dispatch(
                getRevenueData({
                    page: 1,
                    perPage: rowsPerPage,
                    filter: "6",
                    tableFilter: list_data[tableValue].value,
                    beneficiary_condition: receiverId === 0 ? null : receiverNames[receiverId - 1].receiver_name,
                    sColumn: sortColumn,
                    sDirection: sortDirection,
                    startDate,
                    endDate
                })
            )

            dispatch(setDateFilter({
                type: "revenue",
                status: true,
                data: {
                    startDate,
                    endDate
                }
            }))
        }
    }, [endDate, startDate])

    return (store.data && <>
        <CardHeader className='border-bottom'>
            <CardTitle tag='h4'>Revenue:  ${kFormatter(home_data.data?.Financial?.totalRevenue?.value)}</CardTitle>
        </CardHeader>


        <Row className='mx-0 mt-1 mb-50'>
            <Col sm={6} className="d-flex align-items-center flex-sm-row flex-column ">

                <div className="d-flex align-items-center">
                    <ReactDatePicker
                        peekNextMonth
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => {
                            if (date) {
                                const formattedDate = moment(date, 'YYYY-MM-DD', true).isValid() ? moment(date).format('YYYY-MM-DD') : null

                                setStartDate(formattedDate)
                            } else {
                                setStartDate(null)
                            }
                        }}
                        selected={startDate ? moment(startDate).toDate() : null}
                        placeholderText="From"
                        className={`form-control`}
                    />
                </div>
                <div className="d-flex align-items-center date_border mt-sm-0 mt-2 margin_date">
                    <ReactDatePicker
                        peekNextMonth
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                        onChange={(date) => {
                            if (date) {
                                const formattedDate = moment(date, 'YYYY-MM-DD', true).isValid() ? moment(date).format('YYYY-MM-DD') : null

                                setEndDate(formattedDate)
                            } else {
                                setEndDate(null)
                            }
                        }}
                        selected={endDate ? moment(endDate).toDate() : null}
                        placeholderText="To"
                        className={`form-control`}
                    />
                </div>
            </Col>
            <Col className='d-flex flex-column align-items-end' sm='6'>
                <div className='d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1'>
                    <UncontrolledDropdown className='chart-dropdown ml-3'>
                        <DropdownToggle color='' className='bg-transparent btn-sm border-0 p-50 text_color'>
                            {!filterValue || filterValue === 6 ? 'Quarterly' : label_data[filterValue - 1]?.label}
                        </DropdownToggle>
                        <DropdownMenu end style={{
                            maxHeight: "400px",
                            overflowY: "auto"
                        }}>
                            {label_data.map((item) => (
                                <DropdownItem
                                    key={item.id}
                                    onClick={() => { setFilterValue(item.id); handleFilter(item.id) }}
                                    className='w-100 text_color'
                                >
                                    {item.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>

                    {filterValue ? <Button onClick={() => {
                        setFilterValue(0)
                        setTableValue(0)
                        setReceiverId(0)
                        dispatch(getRevenueData({
                            page: 1,
                            perPage: rowsPerPage,
                            filter: 0,
                            sColumn: sortColumn,
                            sDirection: sortDirection
                        }))

                        setEndDate(null)
                        setStartDate(null)
                        dispatch(setDateFilter({
                            type: "revenue",
                            status: false,
                            data: {
                            }
                        }))
                    }} className='mr-3'>
                        Clear
                    </Button> : ""}
                </div>
                <strong style={{ color: "#40c07b" }}>*For now, all values are from inception</strong>
            </Col>
            {(store.barGraph || date_filter.status) && <Col sm={12}>
                <ApexBarChart
                    direction={isRtl ? 'rtl' : 'ltr'}
                    info={colors.info.main}
                    title="Revenue"
                    data={store.barGraph}
                    filterValue={filterValue}
                    date_filter={{ ...date_filter, count }}
                />
            </Col>}
            <Row className='d-flex align-items-center'>
                <Col sm='12' md={6} className='d-flex justify-content-start align-items-center mt-2 mt-sm-0 main_export_div' >
                    <div className='d-flex align-items-center'>
                        <Label for='sort-select'>show</Label>
                        <Input
                            className='dataTable-select'
                            type='select'
                            id='sort-select'
                            value={rowsPerPage}
                            onChange={e => handlePerPage(e)}
                        >
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option>
                        </Input>
                        <Label for='sort-select'>entries</Label>

                    </div>

                </Col>
                <Col sm={12} md={6} className='d-flex justify-content-end align-items-center mt-2 mt-sm-0 main_export_div mr-2'>

                    {/* <Label style={{
                    marginTop: "22px",
                    marginRight: "10px"
                }}>Filter Type:</Label>
                    <UncontrolledDropdown className='chart-dropdown d-flex flex-column mr-2'>
                        <Label>Beneficiary Name:</Label>
                        <DropdownToggle color='' className='bg-transparent btn-sm border-0 p-50 text_color revenue_dropdown'>
                            {!receiverId ? 'Beneficiary' : receiverNames[receiverId - 1]?.receiver_name.length > 50 ? `${receiverNames[receiverId - 1]?.receiver_name.slice(0, 50)}...` : receiverNames[receiverId - 1]?.receiver_name}
                        </DropdownToggle>
                        <DropdownMenu end style={{
                            maxHeight: "400px",
                            overflowY: "auto"
                        }}>
                            <DropdownItem
                                key={0}
                                onClick={() => { setReceiverId(0); handelReceiverfilter(0) }}
                                className='w-100 text_color'
                            >
                                All
                            </DropdownItem>
                            {receiverNames.map((item, index) => (
                                <DropdownItem
                                    key={index + 1}
                                    onClick={() => { setReceiverId(index + 1); handelReceiverfilter(index + 1) }}
                                    className='w-100 text_color'
                                >
                                    {item.receiver_name}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown> */}
                    <div className='main_button_div d-flex '>
                        <Button onClick={() => {
                            handleDownload(2)
                        }} className='' style={{
                            marginRight: "15px"
                        }}>
                            Export CSV
                        </Button>
                        <Button onClick={() => {
                            handleDownload(1)
                        }} className='' style={{
                            marginRight: "15px"
                        }}>
                            Export PDF
                        </Button>
                    </div>

                    <UncontrolledDropdown className='chart-dropdown ' style={{ display: 'flex', flexDirection: "column" }}>
                    
                        <DropdownToggle color='' className='bg-transparent btn-sm border-0 p-50 text_color '>
                            {!tableValue ? 'All' : list_data[tableValue]?.label}
                        </DropdownToggle>
                        <DropdownMenu end>
                            {list_data.map((item) => (
                                <DropdownItem
                                    key={item.id}
                                    onClick={() => { setTableValue(item.id); handeltabelfilter(item.id) }}
                                    className='w-100 text_color'
                                >
                                    {item.label}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
            </Row>

        </Row>
        {/* {console.log({first: dataToRender()})} */}
        {store.loading && store.data.length === 0 ? <Loader /> : <div className='react-dataTable'>
            <DataTable
                noHeader
                pagination
                paginationServer
                className='react-dataTable'
                onSort={handleSort}
                columns={TableColumns}
                sortIcon={<ChevronDown size={10} />}
                paginationComponent={CustomPagination}
                data={dataToRender()}
            />
        </div>}
        <Revenue_sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} data={props_data} />
    </>
    )
}


export default memo(Datatable)