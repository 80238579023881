// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getUserList = createAsyncThunk(
  'GET_USERS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getallusers', params})
      if (response.status === 200) {
        return { allData: response.data, data: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getSubscribedUserList = createAsyncThunk(
  'GET_SUBSCRIBED_USERS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/admin_getAll_subscribed', params})

      if (response.status === 200) {
        return { data: response.data.sub_data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getOtherTransactions = createAsyncThunk(
  'GET_OTHER_USERS_TRANSACTIONS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getOtherTransactions', params, data: {user_id : params.user_id}})
      if (response.status === 200) {
        return { allData: response.data, data: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getUserTransactions = createAsyncThunk(
  'GET_USERS_TRANSACTIONS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getusertransactions', params, data: {user_id : params.user_id}})
      if (response.status === 200) {
        return { allData: response.data, data: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getUserPayouts = createAsyncThunk(
  'GET_USERS_PAYOUT_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getuserpayouts', params, data: {user_id : params.user_id}})
      if (response.status === 200) {
        return { allData: response.data, data: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getUserContents = createAsyncThunk(
  'GET_USERS_CONTENTS_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/usercontents', params, data: {user_id : params.user_id}})
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const getSingleUser = createAsyncThunk(
  'GET_SINGLE_USER', 
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getsingleuser', data})
      return {response: response.data}
    } catch (err) {
      if (err.response.data.logout) {
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const userSlice = createSlice({
  name: 'USERS',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser:{},
    toastDetails: {
      toast_status: null,
      status_code: null,
      toast_message: null
    },
    subscribed_users_data:[],
    loading: false,
    transactions: {
      data: [],
      params: {},
      allData: [],
      total: 1,
      loading: false
    },
    other_transactions: {
      data: [],
      params: {},
      allData: [],
      total: 1,
      loading: false
    },
    payouts: {
      data: [],
      params: {},
      allData: [],
      total: 1,
      loading: false
    },
    contents: {
      data: [],
      params: {},
      allData: [],
      total: 1,
      loading: false
    }
  },
  reducers: {
    updateUserStatus: (state, action) => {
      state.selectedUser.status = action.payload
    }
  },
  extraReducers: {
    [getUserList.pending]: (state) => {
      state.data = []
      state.loading = true
    },
    [getUserList.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_message = action.payload.message
      state.toastDetails.toast_status = true
      state.toastDetails.status_code = action.payload.status
    },
    [getUserList.fulfilled]: (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    },
    [getSubscribedUserList.pending]: (state) => {
      state.data = []
      state.loading = true
    },
    [getSubscribedUserList.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_message = action.payload.message
      state.toastDetails.toast_status = true
      state.toastDetails.status_code = action.payload.status
    },
    [getSubscribedUserList.fulfilled]: (state, action) => {
      state.loading = false
      state.subscribed_users_data = action.payload.data
      state.total = action.payload.totalPages
    },
    [getSingleUser.pending]: (state) => {
      state.selectedUser = {}
      state.loading = true
    },
    [getSingleUser.rejected]: (state, action) => {
      state.loading = false
      state.toastDetails.toast_message = action.payload.message
      state.toastDetails.toast_status = true
      state.toastDetails.status_code = action.payload.status
    },
    [getSingleUser.fulfilled]: (state, action) => {
      state.loading = false
      state.selectedUser = action.payload.response.data
    },
    [getUserTransactions.pending]: (state) => {
      state.transactions.data = []
      state.transactions.loading = true
    },
    [getUserTransactions.rejected]: (state) => {
      state.transactions.loading = false
    },
    [getUserTransactions.fulfilled]: (state, action) => {
      state.transactions.loading = false
      state.transactions.data = action.payload.data
      state.transactions.params = action.payload.params
      state.transactions.allData = action.payload.allData
      state.transactions.total = action.payload.totalPages
    },
    [getOtherTransactions.pending]: (state) => {
      state.other_transactions.data = []
      state.other_transactions.loading = true
    },
    [getOtherTransactions.rejected]: (state) => {
      state.other_transactions.loading = false
    },
    [getOtherTransactions.fulfilled]: (state, action) => {
      state.other_transactions.loading = false
      state.other_transactions.data = action.payload.data
      state.other_transactions.params = action.payload.params
      state.other_transactions.allData = action.payload.allData
      state.other_transactions.total = action.payload.totalPages
    },
    [getUserPayouts.pending]: (state) => {
      state.payouts.data = []
      state.payouts.loading = true
    },
    [getUserPayouts.rejected]: (state) => {
      state.payouts.loading = false
    },
    [getUserPayouts.fulfilled]: (state, action) => {
      state.payouts.loading = false
      state.payouts.data = action.payload.data
      state.payouts.params = action.payload.params
      state.payouts.allData = action.payload.allData
      state.payouts.total = action.payload.totalPages
    },
    [getUserContents.pending]: (state) => {
      state.contents.data = []
      state.contents.loading = true
    },
    [getUserContents.rejected]: (state) => {
      state.contents.loading = false
    },
    [getUserContents.fulfilled]: (state, action) => {
      state.contents.loading = false
      state.contents.data = action.payload.response.data
      state.contents.params = action.payload.params
      state.contents.allData = action.payload.response.data
      state.contents.total = action.payload.totalPages
    }
  }
})

export const {updateUserStatus} = userSlice.actions
export default userSlice.reducer
