import React, { Fragment, useEffect } from 'react'
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import BarChart from './charts/ChartjsBarChart'
import Datatable from './tables/Datatable'
import { useDispatch } from 'react-redux'
import { getHomeData } from '../../redux/home_slice'
const RevenueRootPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getHomeData())
    }, [])

    return (
        <>
            <Fragment>
                <Row className='mx-0'>
                    <Card>
                            <Col sm={12}>
                                <Datatable />
                            </Col>
                    </Card>
                </Row>
            </Fragment>

        </>
    )
}

export default RevenueRootPage