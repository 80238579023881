// ** React Imports
import { Fragment, useState, useEffect, memo } from 'react'

// **Sidebar
import ReplyMessageSidebar from './Reply_Sidebar'

// ** Store & Actions
import { getSupportMessages, selectMessage } from '../../../redux/support_message_slice'
import { useSelector, useDispatch } from 'react-redux'

// ** Third Party Components
import ReactPaginate from 'react-paginate'
import { ChevronDown, MessageSquare } from 'react-feather'
import DataTable from 'react-data-table-component'
import moment from 'moment'

//custom component
import Loader from '../../../components/loader'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, Input, Label, Row, Col, Badge } from 'reactstrap'

// ** Styles
import '@styles/react/libs/tables/react-dataTable-component.scss'


const SupportMessageList = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.support_message_slice)

  // ** States
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchValue, setSearchValue] = useState('')
  const [sortColumn, setSortColumn] = useState('created_at')
  const [sortDirection, setSortDirection] = useState('desc')
  const [sidebarOpen, setSidebarOpen] = useState(false)

  // ** Function to toggle sidebar
  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)


  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getSupportMessages({
        page: currentPage,
        perPage: rowsPerPage,
        q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection
      })
    )
  }, [dispatch])

  // ** function to handle sorting
  const handleSort = (column, sortDirection) => {
    dispatch(
      getSupportMessages({
        page: 1,
        perPage: rowsPerPage,
        q: searchValue,
        sColumn: column.selector,
        sDirection: sortDirection
      })
    )
    setSortColumn(column.selector)
    setSortDirection(sortDirection)
  }

  // ** Function to handle filter
  const handleFilter = e => {
    setSearchValue(e.target.value)

    dispatch(
      getSupportMessages({
        page: currentPage,
        perPage: rowsPerPage,
        q: e.target.value,
        sColumn: sortColumn,
        sDirection: sortDirection
      })
    )
  }

  // ** Function to handle Pagination and get data
  const handlePagination = page => {
    dispatch(
      getSupportMessages({
        page: page.selected + 1,
        perPage: rowsPerPage,
        q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection
      })
    )
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle per page
  const handlePerPage = e => {
    dispatch(
      getSupportMessages({
        page: currentPage,
        perPage: parseInt(e.target.value),
        q: searchValue,
        sColumn: sortColumn,
        sDirection: sortDirection
      })
    )
    setRowsPerPage(parseInt(e.target.value))
  }

  const handleView = (support_message, index) => {
    dispatch(
      selectMessage({
        support_message,
        index
      })
    )
  }

  // ** Table Server Side Column
  const TableColumns = [
    {
      sortable: true,
      name: 'S.no',
      maxWidth: '40px',
      cell: (row, index) => <p>{((currentPage - 1) * rowsPerPage) + (index + 1)} </p>
    },
    {
      sortable: true,
      name: 'name',
      minWidth: '150px',
      selector: 'name'
    },
    {
      sortable: true,
      name: 'Subject',
      minWidth: '150px',
      selector: 'subject'
    },
    {
      sortable: true,
      name: 'Category',
      minWidth: '225px',
      selector: 'category',
      cell: row => <p className='cursor-pointerp'> {row.category === "1" ? 'General Enquiry' : row.category === "2" ? 'System Downtime' : row.category === "3" ? "Feature request" : "None"}</p>
    },
    {
      sortable: true,
      name: 'Status',
      minWidth: '100px',
      selector: 'status',
      cell: row => <Badge className='cursor-pointerp' color={row.status === "0" ? 'info' : 'success'}> {row.status === "0" ? "Open" : "Close"}</Badge>
    },
    {
      sortable: true,
      name: 'Date',
      minWidth: '150px',
      selector: 'created_at',
      cell: row => <p className='cursor-pointerp'> {moment(row.created_at).utcOffset(0).format('MM/DD/YY hh:mm a')} </p>
    },
    {
      sortable: true,
      name: 'Action',
      minWidth: '150px',
      selector: (row, index) => <MessageSquare className='cursorPointer' onClick={() => {
        handleView(row, index)
        toggleSidebar()
      }} size={18} />
    }
  ]


  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(store.total / rowsPerPage)

    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        breakLabel='...'
        pageCount={Math.ceil(count) || 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePagination(page)}
        pageClassName='page-item'
        breakClassName='page-item'
        nextLinkClassName='page-link'
        pageLinkClassName='page-link'
        breakLinkClassName='page-link'
        previousLinkClassName='page-link'
        nextClassName='page-item next-item'
        previousClassName='page-item prev-item'
        containerClassName={
          'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-1'
        }
      />
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      if (store.allData.data && store.allData.data.length > 0) {
        return store.allData.data.slice(0, rowsPerPage)
      }
    }
  }

  return (
    <Fragment>
      <Card>
        <CardHeader className='border-bottom'>
          <CardTitle tag='h4'>Support Messages</CardTitle>
        </CardHeader>
        <Row className='mx-0 mt-1 mb-50'>
          <Col sm='6'>
            <div className='d-flex align-items-center'>
              <Label for='sort-select'>show</Label>
              <Input
                className='dataTable-select'
                type='select'
                id='sort-select'
                value={rowsPerPage}
                onChange={e => handlePerPage(e)}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
              <Label for='sort-select'>entries</Label>
            </div>
          </Col>
          <Col className='d-flex align-items-center justify-content-sm-end mt-sm-0 mt-1' sm='6'>
            <Label className='me-1' for='search-input'>
              Search
            </Label>
            <Input
              className='dataTable-filter'
              type='text'
              bsSize='sm'
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </Col>
        </Row>
        {store.loading && store.data.length === 0 ? <Loader /> : <div className='react-dataTable'>
          <DataTable
            noHeader
            pagination
            paginationServer
            className='react-dataTable'
            onSort={handleSort}
            columns={TableColumns}
            sortIcon={<ChevronDown size={10} />}
            paginationComponent={CustomPagination}
            data={dataToRender()}
          />
        </div>}
      </Card>
      <ReplyMessageSidebar open={sidebarOpen} toggleSidebar={toggleSidebar} />
    </Fragment>
  )
}

export default memo(SupportMessageList)
