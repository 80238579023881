// ** React Imports
import { Fragment, useEffect } from "react"

// ** Custom Components
import NavbarUser from "./NavbarUser"
import { getProfile } from '../../../../redux/auth_slice'

// ** Third Party Components
import { Sun, Moon, Menu } from "react-feather"

// ** Reactstrap Imports
import { NavItem, NavLink } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

const ThemeNavbar = (props) => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth_slice)
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      dispatch(
        getProfile()
      )
    }
  }, [dispatch])

  return (
    <Fragment>
      <div className="bookmark-wrapper d-flex align-items-center">
        <ul className="navbar-nav d-xl-none">
          <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}
            >
              <Menu className="ficon" />
            </NavLink>
          </NavItem>
        </ul>
      </div>
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
