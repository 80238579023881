// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getAllContents = createAsyncThunk(
  'GET_CONTENT_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({ sub_url: '/admin/getallcontent', params })
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
        localStorage.clear()
        sessionStorage.clear()
      }
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

  })

export const getPendingContents = createAsyncThunk(
  'GET_PENDING_CONTENT_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({ sub_url: '/admin/getpendingContent', params })
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
        localStorage.clear()
        sessionStorage.clear()
      }
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

  })

export const getAllBuyersList = createAsyncThunk(
  'GET_CONTENT_BUYERS_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({ sub_url: '/admin/getallbuyers', data: { content_id: params.content_id }, params })
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
        localStorage.clear()
        sessionStorage.clear()
      }
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

  })

export const getAllReadersList = createAsyncThunk(
  'GET_CONTENT_READERS_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({ sub_url: '/admin/getallreaders', data: { content_id: params.content_id }, params })
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
        localStorage.clear()
        sessionStorage.clear()
      }
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }

  })

export const ContentSlice = createSlice({
  name: 'CONTENTS',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false,
    selectedData: {},
    seriesContent: [],
    contentBuyers: {
      model: {
        show: false,
        index: 0
      },
      data: [],
      total: 0,
      params: {},
      allData: [],
      loading: false
    },
    contentReaders: {
      model: {
        show: false,
        index: 0
      },
      data: [],
      total: 0,
      params: {},
      allData: [],
      loading: false
    },
    approve_content_modal: {
      show: false,
      data: {

      }
    }
  },
  reducers: {

    selectData: (state, action) => {
      state.selectedData = action.payload.data
      state.seriesContent = action.payload.contents
    },
    buyesTable: (state, action) => {
      state.contentBuyers.model.show = action.payload.show
      state.contentBuyers.model.index = action.payload.index
    },
    readersTable: (state, action) => {
      state.contentReaders.model.show = action.payload.show
      state.contentReaders.model.index = action.payload.index
    },
    updateContentStatus: (state, action) => {
      const restricted_status = action?.payload?.restricted ? action?.payload?.restricted : []
      if (action.payload.index) {
        state.seriesContent[action.payload.index].status = action.payload.status
      } else {
        state.selectedData.status = action.payload.status
        state.seriesContent.map((item) => {
          if (!restricted_status?.includes(item.status)) {
            item.status = action.payload.status
          }
        })

      }
    },
    updateContentpenStatus: (state, action) => {
      if (action.payload.id) {
        state.seriesContent = state.seriesContent.map(item => {
          if (item.id === action.payload.id) {
            item.status = action.payload.status
          }
          return item
        })
      } else {
        state.selectedData.status = action.payload.status
      }
    },
    selectData_update: (state, action) => {
      const key_name = action.payload.key_name
      const key_value = action.payload.key_value

      state.selectedData = { ...state.selectedData, [key_name]: key_value }
    },
    approveModal: (state, { payload }) => {
      state.approve_content_modal.show = payload.show
      state.approve_content_modal.data = payload.data
    }
  },
  extraReducers: {
    [getAllContents.pending]: (state) => {
      state.loading = true
      state.data = []
    },
    [getAllContents.rejected]: (state) => {
      state.loading = false
    },
    [getAllContents.fulfilled]: (state, action) => {
      state.data = action.payload.response.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.loading = false
    },
    [getPendingContents.pending]: (state) => {
      state.loading = true
      state.data = []
    },
    [getPendingContents.rejected]: (state) => {
      state.loading = false
    },
    [getPendingContents.fulfilled]: (state, action) => {
      state.data = action.payload.response.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.loading = false
    },
    [getAllBuyersList.pending]: (state) => {
      state.contentBuyers.loading = true
    },
    [getAllBuyersList.rejected]: (state) => {
      state.contentBuyers.loading = false
    },
    [getAllBuyersList.fulfilled]: (state, action) => {
      state.contentBuyers.data = action.payload.response.data
      state.contentBuyers.params = action.payload.params
      state.contentBuyers.allData = action.payload.allData
      state.contentBuyers.total = action.payload.totalPages
      state.contentBuyers.loading = false
    },
    [getAllReadersList.pending]: (state) => {
      state.contentReaders.loading = true
    },
    [getAllReadersList.rejected]: (state) => {
      state.contentReaders.loading = false
    },
    [getAllReadersList.fulfilled]: (state, action) => {
      state.contentReaders.data = action.payload.response.data
      state.contentReaders.params = action.payload.params
      state.contentReaders.allData = action.payload.allData
      state.contentReaders.total = action.payload.totalPages
      state.contentReaders.loading = false
    }
  }
})

export const { selectData, buyesTable, readersTable, updateContentStatus, selectData_update, updateContentpenStatus, approveModal } = ContentSlice.actions
export default ContentSlice.reducer
