// ** React Import
import { useState } from 'react'

// ** Custom Components
import Sidebar from '@components/sidebar'
import Toaster from "../../../components/toaster"

// ** Third Party Components
import { useForm } from 'react-hook-form'
import toast from "react-hot-toast"

// ** Reactstrap Imports
import { Button, Form, Input } from 'reactstrap'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import {updateMessageStatus} from '../../../redux/support_message_slice'
import { axiosRequest } from '../../../http'

const ReplyMessageSidebar = ({ open, toggleSidebar }) => {
  // ** States
  const [message, setMessage] = useState("")

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.support_message_slice)

  const {selectedSupportMessage} = store

  // ** Vars
  const {
    handleSubmit
  } = useForm()


  // ** Function to handle form submit
  const onSubmit = async () => {
    try {
        const response = await axiosRequest({sub_url: "/admin/submitreply", data :{message, support_message_id: selectedSupportMessage.id}})
        if (response.status === 200) {
            toggleSidebar()
            dispatch(
              updateMessageStatus({
                status : 1,
                index: selectedSupportMessage.index
              })
            )
            return toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
        }
    } catch (err) {
        return toast.success(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
    }
  }

  return (
    <Sidebar
      size='lg'
      open={open}
      title='Reply to User'
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>

        <div className='d-flex'>
            <h5>To :</h5> <span className='ms-1'>{selectedSupportMessage.name}</span>
        </div>
        <div className='d-flex'>
            <h5>Subject : </h5> <span className='ms-1'>{selectedSupportMessage.subject}</span>
        </div>

        <div className='d-flex'>
            <h5>Category :</h5> <span className='ms-1'>{selectedSupportMessage.category === "1" ? 'General Enquiry' : selectedSupportMessage.category === "2" ?  'System Downtime' : selectedSupportMessage.category === "3" ? "Feature request" : "None"}</span>
        </div>

        <div className='d-flex'>
            <h5>User's Message : </h5> <span className='ms-1'>{selectedSupportMessage.message}</span>
        </div>

        {(selectedSupportMessage.status === 0 || selectedSupportMessage.status === "0") &&

        <div className='d-flex flex-column mb-2 mt-2'>
            <h5>Reply to user : </h5>
            <Input 
            row="8" 
            type='textarea' 
            placeholder='message'
            onChange={(e) => {
                setMessage(e.target.value)
            }}>                
            </Input>
        </div> }
          
        {(selectedSupportMessage.status === 1 || selectedSupportMessage.status === "1") && <div className='d-flex'>
            <h5>Admin's Reply : </h5> <span className='ms-1'>{selectedSupportMessage.response}</span>
        </div>}


        {(selectedSupportMessage.status === 0 || selectedSupportMessage.status === "0") && <Button type='submit' className='me-1 mt-2' color='primary'>
          Submit
        </Button>}

        
        <Button type='reset' className='mt-2' color='secondary' outline onClick={toggleSidebar}>
          Cancel
        </Button>
      </Form>
    </Sidebar>
  )
}

export default ReplyMessageSidebar
