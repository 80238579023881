// ** React Imports
import { useEffect, useState } from 'react'

// ** Third Party Components
import Chart from 'react-apexcharts'
import { kFormatter } from '@utils'

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap'

const SupportTracker = (props) => {
  // ** State
  const [data, setData] = useState(null)

  useEffect(() => {
    setData({
      title: "Support Tracker",
      last_days: [
          "Last 28 Days",
          "Last Month",
          "Last Year"
      ],
      totalTicket: 163,
      newTicket: 29,
      openTicket: 63,
      responseTime: 1
  })
    return () => setData(null)
  }, [])

  const options = {
      plotOptions: {
        radialBar: {
          size: 150,
          offsetY: 20,
          startAngle: -150,
          endAngle: 150,
          hollow: {
            size: '70%'
          },
          track: {
            background: 'transparent',
            strokeWidth: '100%'
          },
          dataLabels: {
            name: {
              offsetY: -5,
              fontFamily: 'Montserrat',
              fontSize: '1rem',
              fill: ["white"]
            },
            value: {
              offsetY: 15,
              fontFamily: 'Montserrat',
              fontSize: '1.714rem',
              fill: ["white"]
            }
          }
        }
      },
      colors: ["#ea5455"],
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ["#7367f0"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        dashArray: 8
      },
      labels: ['Completed Payouts']
      
    },
    series = [props.percentage]

  return data !== null ? (
    <Card>
      <CardHeader className='pb-0'>
        <CardTitle tag='h4'>{props.label}</CardTitle>
      
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm='2' className='d-flex flex-column flex-wrap text-center mt-1'>
            <h1 className='font-large-2 fw-bolder mt-2 mb-0'>${kFormatter(props.title)}</h1>
            <CardText>Total Payouts</CardText>
          </Col>
          <Col sm='10' className='d-flex justify-content-center mt-3'>
            <Chart options={options} series={series} type='radialBar' height={270} id='support-tracker-card' />
          </Col>
        </Row>
        <div className='d-flex justify-content-evenly mt-5'>
          <div className='text-center'>
            <CardText className='mb-50'>Completed Payouts</CardText>
            <span className='font-large-1 fw-bold'>${props.pendingPayout}</span>
          </div>
          <div className='text-center'>
            <CardText className='mb-50'>Pending Payouts</CardText>
            <span className='font-large-1 fw-bold'>${props.completePayout}</span>
          </div>
        </div>
      </CardBody>
    </Card>
  ) : null
}
export default SupportTracker
