// ** Third Party Components
import classnames from 'classnames'
import { TrendingUp, User, Box, DollarSign } from 'react-feather'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody, CardText, Row, Col } from 'reactstrap'
// import AvgSessions from '../charts/AvgSessions'
import SupportTracker from '../charts/SupportTracker'
import TinyChartStats from '../charts/TinyChartStats'

export const AvtarCard = ({data, cols}) => {
  return data.map((item, index) => {
    // const colMargin = Object.keys(cols)
    // const margin = index === 1 ? 'sm' : colMargin[0]
    return (
      <Col
        key={index}
        {...cols}
        className={classnames({
          [`mb-2`]: index !== data.length - 1 && data.length > 2
        })}
      >
        <div className='d-flex align-items-center'>
          <Avatar color={item.color} icon={item.icon} className='me-2' />
          <div className='my-auto'>
            <h4 className='fw-bolder mb-0'>{item.title}</h4>
            <CardText className='font-small-3 mb-0'>{item.subtitle}</CardText>
          </div>
        </div>
      </Col>
    )
  })
}

const StatsCard = ({ cols, component_title, data }) => {


  const renderData = () => {
    return <AvtarCard data={data} cols={cols} />
  }

  return (
    <Card className='card-statistics'>
      <CardHeader>
        <CardTitle tag='h3'>{component_title}</CardTitle>
      </CardHeader>
      <CardBody className='statistics-body pt-0'>

        {/* <SupportTracker />
        <TinyChartStats /> */}
        <Row>{renderData()}</Row>
      </CardBody>
    </Card>
  )
}

export default StatsCard
