import axios from "axios"
// import {
//     getCookie
// } from './cookies'

import { isUserLoggedInToken } from '@utils'

// const mytoken = getCookie('auth_token')

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json'
    }
})

export const axiosRequest = async (props) => {
    const res = await api.post(props.sub_url, props.data, (props.params && props.params !== undefined) && {
        params: {
            category: props.params.category ? props.params.category : '',
            content_type: props.params.contentType ? props.params.contentType : "",
            q: props.params.q,
            page: props.params.page,
            perPage: props.params.perPage,
            sColumn: props.params.sColumn,
            sDirection: props.params.sDirection,
            filterBy: props.params.filterBy,
            filter: props.params.filter ?? 0,
            tableFilter: props?.params?.tableFilter ?? "all",
            url: props?.params?.url ?? null,
            dataFilter: props?.params?.dataFilter ?? null,
            isCreator: props?.params?.is_creator ?? null,
            start_date: props?.params?.startDate ?? null,
            end_date: props?.params?.endDate ?? null,
            status:  props?.params?.status ?? null,
            s_filter: props?.params?.s_filter ?? null,
            beneficiary_condition: props?.params?.beneficiary_condition ?? null
        }
    }
    )
    return res
}

api.interceptors.request.use(function (config) {
    config.headers["auth_token"] = sessionStorage.getItem('auth_token') ? isUserLoggedInToken() : null
    return config
})

export default api