// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getAdminHistoryList = createAsyncThunk(
  'GET_HISTORY_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/history', params})
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (err.response.data.logout) {
          localStorage.clear()
          sessionStorage.clear()
      }
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const CreditSlice = createSlice({
  name: 'HISTORY',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false
  },
  reducers: {},
  extraReducers: {
    [getAdminHistoryList.pending]: (state) => {
      state.loading = true
    },
  [getAdminHistoryList.rejected]: (state) => {
    state.loading = false
  },
    [getAdminHistoryList.fulfilled]: (state, action) => {
      state.data = action.payload.response.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.loading = false
    }
  }
})

export default CreditSlice.reducer
