// ** React Imports
import { Fragment, lazy } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"

// ** Route Components
import PrivateRoute from "@components/routes/PrivateRoute"

// ** Utils
import { isObjEmpty } from "@utils"
import SupportMessageList from "../../views/supportmessage/support_message_view/support_message_list"
import ContentView from "../../views/content/content_view/contentView"
import SeriesView from "../../views/content/content_view/seriesView"
import ProfitRootPage from "../../views/profit"
import RevenueRootPage from "../../views/Revenue"
import Payoutdashboard from "../../views/payoutdashboard"
import CreatorRootpage from "../../views/creatordashboard"
import UserRootpage from "../../views/usersdashboard"


const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = "%s - Novatoons - Admin Panel"

// ** Default Route
const DefaultRoute = `${process.env.REACT_APP_URL_PREFIX}/home`
const Home = lazy(() => import("../../views/home"))
const Login = lazy(() => import("../../views/Login"))
const Error = lazy(() => import("../../views/Error"))

const User = lazy(() => import("../../views/user"))
const UserView = lazy(() => import("../../views/user/user_view/userInfo"))

const Contents = lazy(() => import("../../views/content"))
const AllContents = lazy(() => import("../../views/allcontent "))
const ApproveContents = lazy(() => import("../../views/approveContent"))
const RejectedContents = lazy(() => import("../../views/rejectedComic"))

const Subscriptionsplan = lazy(() => import("../../views/subscriptionsplan"))
const SubscriberList = lazy(() => import("../../views/subscriptionsplan/subscription_plan_view/SubscriberList"))
const CreditPlans = lazy(() => import("../../views/creditplan"))
const CreateCreditPlan = lazy(() => import("../../views/createcreditplan"))
const CreditPlansUsers = lazy(() => import("../../views/creditplan/credit_plan_view/creditPlanUsersList"))
const ReportedComments = lazy(() => import("../../views/reportedcomment"))
const Settings = lazy(() => import("../../views/settings"))
const AdminHistory = lazy(() => import("../../views/adminHistory"))
const SubscribedUsers = lazy(() => import("../../views/subscribedusers"))
const Payouts = lazy(() => import("../../views/patouts"))


// ** Merge Routes
const Routes = [
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/home`,
    element: <Home />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/`,
    index: true,
    element: <Navigate replace to={DefaultRoute} />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/users`,
    element: <User />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/users/:id`,
    element: <UserView />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/contents`,
    element: <Contents />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/approvedcontent`,
    element: <AllContents />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/pendingcontent`,
    element: <ApproveContents />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/rejectedcontent`,
    element: <RejectedContents />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/content/:id`,
    element: <ContentView />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/series/:id`,
    element: <SeriesView />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/subscriptionsplans`,
    element: <Subscriptionsplan />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/subscriptionsusers`,
    element: <SubscribedUsers />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/subscriptionsplans/:id`,
    element: <SubscriberList />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/creditplans`,
    element: <CreditPlans />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/profit`,
    element: <ProfitRootPage />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/revenue`,
    element: <RevenueRootPage />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/payoutdashboard`,
    element: <Payoutdashboard />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/creatordashboard`,
    element: <CreatorRootpage />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/userdashboard`,
    element: <UserRootpage />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/createcreditplans`,
    element: <CreateCreditPlan />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/editcreditplans/:id`,
    element: <CreateCreditPlan />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/creditplans/:id`,
    element: <CreditPlansUsers />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/reportedcomments`,
    element: <ReportedComments />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/supportmessages`,
    element: <SupportMessageList />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/settings`,
    element: <Settings />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/history`,
    element: <AdminHistory />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/payouts`,
    element: <Payouts />,
    meta: {
      layout: 'vertical',
      restricted: true
    }
  },
  {
    path: `${process.env.REACT_APP_URL_PREFIX}/login`,
    element: <Login />,
    meta: {
      layout: "blank",
      authRestricted: true
    }
  },
  {
    path: "*",
    element: <Error />,
    meta: {
      layout: "blank",
      authRestricted: true
    }
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        const RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
