import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import {buyesTable, readersTable} from '../../redux/content_slice'

const Model = ({children}) => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.content_slice)

    const handleModelHide = () => {
        dispatch(
            buyesTable({
                show: false,
                index: 0
            })
        )
        dispatch(
            readersTable({
                show: false,
                index: 0
            })
        )
    }

    
    return (
        <Fragment>
            <Modal
                isOpen={store.contentBuyers.model.show || store.contentReaders.model.show}
                fullscreen
                toggle={() => handleModelHide() }
                className='modal-dialog-centered p-5'>

                <ModalHeader toggle={() =>  handleModelHide() } >
                </ModalHeader>
                <ModalBody className='ContentTypeModel'>
                    {children}
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default Model