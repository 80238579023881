import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import { kFormatter } from '@utils'
const convertArrayOfObjectsToPDF = (data, heading, type, columns, columnStyles, download) => {
    const doc = new jsPDF()
    // doc.scale(50, 50)
    // doc.addImage(Applogo, 'svg', 20, 40)

    // doc.text(20, 60, 'Data: ' + dataArray.join(', '))
    // Define the columns for your table
    // Define an empty array to hold the rows of data
    const rows = [...data]

    // Add the table to the PDF document

    doc.setFontSize(11)

    doc.setFont("helvetica")

    // Set text color
    doc.setTextColor("#2E4C6D") // Red color

    doc.text(`Generated Date:  ${moment(new Date()).format('MM-DD-YYYY')}`, 140, 16)

    doc.text(`Type: ${type ? type : "All"}`, 15, 10)
    doc.text(`Total ${heading.name}: ${heading.total}`, 15, 16)

    doc.text(`${'Generated By: admin@admin.com'}`, 140, 10)

    if (download.status) {
        doc.text(`Date range:  ${download.date}`, 140, 22)
    }

    doc.autoTable({
        head: [columns],
        body: rows,
        startY: download.status ? 28 : 22,
        ...(columnStyles ? { columnStyles } : {})
    })

    doc.save(`${heading.name}_data.pdf`)
}

const exportToPdf = (array = [], type, download) => {
    // const link = document.createElement('a')
    const NewOne = []
    let total_revenue = 0
    array.forEach((item, index) => {
        total_revenue += Number(item?.amount)
        NewOne.push([
            index + 1,
            item?.full_name,
            item?.email,
            `$${parseFloat(item?.amount).toFixed(2)}`,
            item?.source_table,
            moment(item?.created_at).format('MM/DD/YY hh:mm a')
        ])
    })

    const columns = ["S.no", "Users", "Email", "Amount", "Type", "Date"]
    total_revenue = parseFloat(total_revenue).toFixed(2)
    convertArrayOfObjectsToPDF(NewOne, { name: "Revenue", total: `$${kFormatter(total_revenue)}` }, type, columns, null, download)
}

const exportToPdfprofit = (array = [], type, download) => {
    // const link = document.createElement('a')
    const NewOne = []
    let total_revenue = 0
    array.forEach((item, index) => {
        if (type === "All") {
            if (item?.source_table === "Payout") {
                total_revenue -= Number(item?.amount)
            } else {
                total_revenue += Number(item?.amount)
            }
        } else {
            total_revenue += Number(item?.amount)
        }
        NewOne.push([
            index + 1,
            item?.full_name,
            item?.email,
            `$${parseFloat(item?.amount).toFixed(2)}`,
            item?.source_table,
            moment(item?.created_at).format('MM/DD/YY hh:mm a')
        ])
    })

    const columns = ["S.no", "Users", "Email", "Amount", "Type", "Date"]
    total_revenue = parseFloat(total_revenue).toFixed(2)
    convertArrayOfObjectsToPDF(NewOne, { name: "Profit", total: `$${kFormatter(total_revenue)}` }, type, columns, null, download)
}

const exportToPdfuser = (array = [], type, name, download) => {
    // const link = document.createElement('a')
    const NewOne = []
    const total_revenue = array.length
    array.forEach((item, index) => {
        NewOne.push([
            index + 1,
            item?.full_name,
            item?.email,
            item.source_type === 1 ? "Email" : item.source_type === 2 ? "Facebook" : item.source_type === 3 ? "Google" : item.source_type === 4 && "Apple",
            item.status === 1 ? "Active" : "Inactive",
            moment(item?.created_at).format('MM/DD/YY')
        ])
    })

    const columns = ["S.no", "Customers", "Email", "Registered With", "Status", "Date"]
    const columnStyles = {
        0: { cellWidth: 15 }, // Adjust the width for the first column (S.no)
        1: { cellWidth: 35 }, // Adjust the width for the second column (Users)
        2: { cellWidth: 50 }, // Adjust the width for the third column (Email)
        3: { cellWidth: 35 }, // Adjust the width for the fourth column (Registered With)
        4: { cellWidth: 20 }, // Adjust the width for the fifth column (Status)
        5: { cellWidth: 25 } // Adjust the width for the sixth column (Date)
    }
    convertArrayOfObjectsToPDF(NewOne, { name, total: `${total_revenue}` }, type, columns, columnStyles, download)
}

const exportToPdfpayouts = (array = [], type, download) => {
    // const link = document.createElement('a')
    const NewOne = []
    let total_revenue = 0
    array.forEach((item, index) => {
        total_revenue += Number(item?.amount)

        NewOne.push([
            index + 1,
            item?.fullname,
            item?.paypal_id,
            `$${parseFloat(item?.amount).toFixed(2)}`,
            item?.transaction_id,
            item.status === "0" ? "Pending" : "Approved",
            moment(item?.created_at).format('MM/DD/YY hh:mm a')
        ])
    })

    const columns = ["S.no", "Users", "Paypal Id", "Amount", "Transaction Id", "Status", "Date"]
    total_revenue = parseFloat(total_revenue).toFixed(2)
    convertArrayOfObjectsToPDF(NewOne, { name: "Payout", total: `$${kFormatter(total_revenue)}` }, type, columns, null, download)
}

// ** Converts table to CSV
function convertArrayOfObjectsToCSV(array) {
    let result
    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const cols = Object.keys(array[0]).map(curr => curr?.toUpperCase())
    const keys = Object.keys(array[0])


    result = ''
    result += cols.join(columnDelimiter)
    result += lineDelimiter

    array.forEach(item => {
        let ctr = 0
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter
            result += item[key]
            ctr++
        })
        result += lineDelimiter
    })

    return result
}

const dataToCSV = (NewOne, link, name) => {
    let csv = convertArrayOfObjectsToCSV(NewOne)
    if (csv === null) return

    const filename = `${name}_data.csv`

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csvcharset=utf-8,${csv}`
    }

    link.setAttribute('href', encodeURI(csv))
    link.setAttribute('download', filename)
    link.click()
}

const exportToCsv = (array = []) => {
    const link = document.createElement('a')
    const NewOne = array.map((item, index) => {

        return {
            ["S.no"]: index + 1,
            ["Users"]: item?.full_name,
            ["Email"]: item?.email,
            ["Amount"]: `$${parseFloat(item?.amount).toFixed(2)}`,
            ["Type"]: item?.source_table,
            ["Date"]: moment(item?.created_at).format('MM/DD/YY hh:mm a')
        }
    })
    dataToCSV(NewOne, link, "Revenue")

}

const exportToCsvProfit = (array = []) => {
    const link = document.createElement('a')
    const NewOne = array.map((item, index) => {
        return {
            ["S.no"]: index + 1,
            ["Users"]: item?.full_name,
            ["Email"]: item?.email,
            ["Amount"]: `$${parseFloat(item?.amount).toFixed(2)}`,
            ["Type"]: item?.source_table,
            ["Date"]: moment(item?.created_at).format('MM/DD/YY hh:mm a')
        }
    })
    dataToCSV(NewOne, link, "Profit")

}

const exportToCsvUser = (array = [], name) => {
    const link = document.createElement('a')

    const NewOne = array.map((item, index) => {
        return {
            ["S.no"]: index + 1,
            ["Customers"]: item?.full_name,
            ["Email"]: item?.email,
            ["Registered With"]: item.source_type === 1 ? "Email" : item.source_type === 2 ? "Facebook" : item.source_type === 3 ? "Google" : item.source_type === 4 && "Apple",
            ["Status"]: item.status === 1 ? "Active" : "Inactive",
            ["Date"]: moment(item?.created_at).format('MM/DD/YY')
        }
    })
    dataToCSV(NewOne, link, name)

}

const exportToCsvPayout = (array = [], name) => {
    const link = document.createElement('a')


    const NewOne = array.map((item, index) => {
        return {
            ["S.no"]: index + 1,
            ["Users"]: item?.fullname,
            ["Paypal Id"]: item?.paypal_id,
            ["Amount"]: `$${parseFloat(item?.amount).toFixed(2)}`,
            ["Transaction Id"]: item?.transaction_id,
            ["Status"]: item.status === "0" ? "Pending" : "Approved",
            ["Date"]: moment(item?.created_at).format('MM/DD/YY')
        }
    })
    dataToCSV(NewOne, link, name)

}

export {
    exportToCsv,
    exportToPdf,
    exportToPdfprofit,
    exportToPdfuser,
    exportToPdfpayouts,
    exportToCsvProfit,
    exportToCsvUser,
    exportToCsvPayout
}