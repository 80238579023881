// ** React Import

// ** Custom Components
import Sidebar from '@components/sidebar'
import moment from 'moment'
import { useEffect, useState } from 'react'


// ** Reactstrap Imports
import { Badge, Button, Form, Input } from 'reactstrap'


const Revenue_sidebar = ({ open, toggleSidebar, data }) => {
  const [details, setDetails] = useState({})

  useEffect(() => {
    if (data?.type) {
      const show_data = {
        title: "",
        data: []
      }

      if (data.type === "Donation") {
        show_data.title = "Donation"
        let data_arr = [
          {
            label: "Donated By",
            key: "sender_name",
            value: ""
          },
          {
            label: "Beneficiary",
            key: "receiver_name",
            value: ""
          },
          {
            label: "Donated On",
            key: "created_at",
            value: ""
          },
          {
            label: "Donated Amount",
            key: "amount",
            value: "$"
          },
          {
            label: "Platform Fees",
            key: "commission",
            value: "$"
          },
          {
            label: "Beneficiary Received Amount",
            key: "beneficiary_amount",
            value: "$"
          }
        ]

        data_arr = data_arr.map(item => {
          Object.keys(data.data).forEach(key => {
            if (item.key === key) {
              item.value = `${item.value}${data.data[key]}`
              if (item.key === "created_at") {
                item.value = moment(item.value).utcOffset(0).format('MM/DD/YY hh:mm a')
              }
            }
          })

          return item
        })

        show_data.data = data_arr

      } else if (data.type === "Promotion") {

        show_data.title = "Promotion"
        let data_arr = [
          {
            label: "Promoted By",
            key: "sender_name",
            value: ""
          },
          {
            label: "Promoted On",
            key: "created_at",
            value: ""
          },
          {
            label: "Type",
            key: "type",
            value: ""
          },
          {
            label: "Stripe Txn. Id",
            key: "transaction_id",
            value: ""
          },
          {
            label: "Promotion Amount",
            key: "amount",
            value: "$"
          },
          {
            label: "Stripe Fees",
            key: "stripe_fees",
            value: "$"
          },
          {
            label: "Platform Amount",
            key: "beneficiary_amount",
            value: "$"
          }
        ]
        let type = null

        data_arr = data_arr.map(item => {
          Object.keys(data.data).forEach(key => {
            if (item.key === key) {
              item.value = `${data.data[key]}`
              if (item.key === "created_at") {
                item.value = moment(item.value).utcOffset(0).format('MM/DD/YY hh:mm a')
              } else if ((item.key.includes("amount") || item.key.includes("commission") || item.key.includes("stripe_fees")) && item.value) {
                item.value = `$${parseFloat(item.value).toFixed(2)}`
              }
            }
            if (item.key.includes("type")) {
              item.value = data.data["series_title"] ? "Collection" : "Single"
              type = data.data["series_title"] ? "Collection" : "Single"
            }
          })

          return item
        })


        if (type === "Collection") {
          data_arr = [
            {
              label: "Series Title",
              key: "",
              value: data.data["series_title"]
            }, ...data_arr
          ]
        } else {
          data_arr = [
            {
              label: "Content Title",
              key: "",
              value: data.data["content_title"]
            }, ...data_arr
          ]
        }

        show_data.data = data_arr

      } else if (data.type === "Purchase") {

        show_data.title = "Purchase"
        let data_arr = [
          {
            label: "Content Title",
            key: "content_title",
            value: ""
          },
          {
            label: "Purchase By",
            key: "sender_name",
            value: ""
          },
          {
            label: "Beneficiary",
            key: "receiver_name",
            value: ""
          },
          {
            label: "Purchased On",
            key: "created_at",
            value: ""
          },
          {
            label: "Comic Price",
            key: "amount",
            value: "$"
          },
          {
            label: "Amount Paid",
            key: "amount",
            value: "$",
            tooltip: {
              data: [
                {
                  label: "Platform Fees",
                  key: "commission",
                  value: "$"
                },
                {
                  label: "Beneficiary Amount",
                  key: "beneficiary_amount",
                  value: "$"
                }
              ]
            }
          }
        ]
        let type = null
        data_arr = data_arr.map(item => {
          Object.keys(data.data).forEach(key => {
            if (item.key === key) {
              item.value = `${data.data[key]}`
              if (item.key === "created_at") {
                item.value = moment(item.value).utcOffset(0).format('MM/DD/YY hh:mm a')
              } else if ((item.key.includes("amount") || item.key.includes("commission") || item.key.includes("stripe_fees")) && item.value) {
                item.value = `$${parseFloat(item.value).toFixed(2)}`
              } else if (item.key.includes("content_title")) {
                item.value = data.data["content_title"]
              }

              if (item.label === "Amount Paid" && item.key === "amount") {
                item.tooltip.data[0].value = parseFloat(data.data[item.tooltip.data[0].key]).toFixed(2)
                item.tooltip.data[1].value = parseFloat(data.data[item.tooltip.data[1].key]).toFixed(2)
              }
            }
            if (item.key.includes("type")) {
              item.value = data.data["series_title"] ? "Collection" : "Single"
              type = data.data["series_title"] ? "Collection" : "Single"
            }

          })

          return item
        })

        if (type === "Collection") {
          data_arr = [
            {
              label: "Series Title",
              key: "",
              value: data.data["series_title"]
            }, ...data_arr
          ]
        }

        show_data.data = data_arr


      } else if (data.type === "Credits Purchase") {

        show_data.title = "Credits Purchase"
        let data_arr = [
          {
            label: "Purchased By",
            key: "full_name",
            value: ""
          },
          {
            label: "Purchased On",
            key: "created_at",
            value: ""
          },
          {
            label: "Stripe Txn. Id",
            key: "transaction_id",
            value: ""
          },
          {
            label: "Amount Paid",
            key: "amount",
            value: "$"
          },
          {
            label: "Stripe Fees",
            key: "stripe_fees",
            value: "$"
          },
          {
            label: "Credits Received",
            key: "credits",
            value: "$"
          }
        ]
        data_arr = data_arr.map(item => {
          Object.keys(data.data).forEach(key => {
            if (item.key === key) {
              item.value = `${data.data[key]}`
              if (item.key === "created_at") {
                item.value = moment(item.value).utcOffset(0).format('MM/DD/YY hh:mm a')
              } else if ((item.key.includes("amount") || item.key.includes("commission") || item.key.includes("stripe_fees")) && item.value) {
                item.value = `$${parseFloat(item.value).toFixed(2)}`
              }

              if (item.key === "credits") {
                item.value = `${parseFloat(data.data[key]).toFixed(2)} Cr`
              }
            }
          })

          return item
        })


        show_data.data = data_arr

      } else if (data.type === "Payout") {
        show_data.title = "Payout"
        let data_arr = [
          {
            label: "Requested By",
            key: "sender_name",
            value: ""
          },
          {
            label: "Requested Amount",
            key: "amount",
            value: "$"
          },
          {
            label: "Payout created",
            key: "created_at",
            value: ""
          },
          {
            label: "Paypal Txn. Id",
            key: "transaction_id",
            value: ""
          },
         
          {
            label: "Paypal ID",
            key: "sender_paypal",
            value: "$"
          },
          {
            label: "Status",
            key: "status",
            value: "$",
            type: "status"
          }
        ]
        data_arr = data_arr.map(item => {
          Object.keys(data.data).forEach(key => {
            if (item.key === key) {
              item.value = `${data.data[key]}`
              if (item.key === "created_at") {
                item.value = moment(item.value).utcOffset(0).format('MM/DD/YY hh:mm a')
              } else if ((item.key.includes("amount") || item.key.includes("commission") || item.key.includes("stripe_fees")) && item.value) {
                item.value = `$${parseFloat(item.value).toFixed(2)}`
              }
            }
          })

          return item
        })


        show_data.data = data_arr
      }
      setDetails(show_data)

    }
  }, [data])

  // ** Store Vars

  return (
    <Sidebar
      size='lg'
      open={open}
      title={details.title}
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
    >
      <Form>


        {details?.data?.length ? details?.data.map(item => {
          return (item.type === "status" ? <div className='d-flex'>
            <h5>{item.label} : </h5> <Badge className='cursor-pointerp mx-2 d-flex align-items-center mb-1' color={item.value === '1' ? "success" : 'info'}>{item.value === '1' ? "Completed" : "Pending"}</Badge>
          </div> : <div className='d-flex'>
            <h5 className='rev_data_style'>{item.label} :</h5> <span className='ms-1 rev_data_style_val'>{item.value}{item.tooltip ? <>&nbsp; &nbsp;<i class="fa fa-info-circle position-relative tooltip_revenue_size" aria-hidden="true"> <span className='tooltip tooltip_revenue'>
              {item.tooltip.data.map(tooltip => <div className='w-100 flex flex-column justify-content-start tool_tip_size'>
                <strong>{tooltip.label}:</strong> {tooltip.value}

              </div>)}
            </span></i></> : ""}</span>
          </div>
          )
        }) : ""}

        {/* <div className='d-flex'>
          <h5>status : </h5> <Badge className='cursor-pointerp mx-2 d-flex align-items-center mb-1' color={'info'}>Approved</Badge>
        </div> */}


        <Button type='reset' className='mt-2' color='secondary' outline onClick={toggleSidebar}>
          Close
        </Button>
      </Form>
    </Sidebar>
  )
}

export default Revenue_sidebar
