// ** React Imports
import { Navigate } from "react-router-dom"
import {  Suspense } from "react"
// import {getCookie} from '../../../http/cookies'

const PrivateRoute = ({ children, route }) => {
  
  if (route) {
    let restrictedRoute = false
    let authRestricted = false

    if (route.meta) {
      restrictedRoute = route.meta.restricted ?? restrictedRoute
      authRestricted = route.meta.authRestricted ?? authRestricted
    }
    // const tokenCookie = getCookie('auth_token')

    const UserLoggedInToken = sessionStorage.getItem('auth_token')
    // !tokenCookie.status 
    // tokenCookie.status
    // return children
    if (UserLoggedInToken === null && restrictedRoute) {
      return <Navigate to={`${process.env.REACT_APP_URL_PREFIX}/login`}  />
    }
    if (UserLoggedInToken && authRestricted) {
      return <Navigate to={`${process.env.REACT_APP_URL_PREFIX}`} />
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
