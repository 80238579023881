import React, {useEffect} from 'react'
// ** React Imports
import { Link, useNavigate } from "react-router-dom"

// ** Custom Components
import Avatar from "@components/avatar"

// ** Third Party Components
import {
  User,
  Power
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"

// ** Default Avatar Image
import defaultAvatar from "@src/assets/images/portrait/small/profileimage.png"
import { useDispatch, useSelector } from "react-redux"
import {isUserLoggedInToken} from '../../../../utility/Utils'
import { handleLogout } from "../../../../redux/auth_slice"

// import { isUserLoggedInToken } from '@utils'

const UserDropdown = () => {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { 
    user,
    isAuth 
  } = useSelector(state => state.auth_slice)

  useEffect(() => {
    if (!isAuth && !isUserLoggedInToken()) {
      navigate(`${process.env.REACT_APP_URL_PREFIX}/login`)
    }
  }, [isAuth])
  
  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{user.name}</span>
          <span className="user-status">Admin</span>
        </div>
        <Avatar
          img={defaultAvatar}
          imgHeight="40"
          imgWidth="40"
          status="online"
        />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/" onClick={(e) => e.preventDefault()}>
          <User size={14} className="me-75" />
          <span className="align-middle">Profile</span>
        </DropdownItem>

        <DropdownItem  onClick={() => {
          dispatch(
            handleLogout()
          )
        }} >
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
