import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormFeedback, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import * as yup from 'yup'
import { axiosRequest } from '../../http'
import Toaster from '../toaster'
import toast from 'react-hot-toast'
import { approveModal, updateContentpenStatus } from '../../redux/content_slice'
import { useNavigate } from 'react-router-dom'

const ApproveContentModal = () => {
    const dispatch = useDispatch()
    const { approve_content_modal } = useSelector(state => state.content_slice)
    const { data: modaldata } = approve_content_modal
    const navigate = useNavigate()
    const settingsSchema = yup.object().shape({
        reason: yup.string().required("This is required field")
    })
    // ** Vars
    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({ mode: 'onSubmit', resolver: yupResolver(settingsSchema) })

    const closeModal = () => {
        dispatch(approveModal({
            show: false,
            data: {}
        }))
    }

    console.log({modaldata})
    const handelFormSubmit = async (data) => {
        try {
            const response = await axiosRequest({
                sub_url: '/admin/approvecontent',
                data: {
                    status: modaldata.status,
                    ...(modaldata?.series_id ? {series_id: modaldata.series_id} : {content_id: modaldata.content_id}),
                    ...(modaldata.status === "reject" ? { reason: data.reason } : {})
                }
            })

            if (response.status === 200) {
                
                if (modaldata.type === "series" && !modaldata?.whole_series) {
                    dispatch(
                        updateContentpenStatus({
                            status: modaldata.status === "approved" ? 1 : modaldata.status === "revert" ? 3 : 4,
                            id: modaldata.content_id
                        })
                    )
                } else {
                    dispatch(
                        updateContentpenStatus({
                            status: modaldata.status === "approved" ? 1 : modaldata.status === "revert" ? 3 : 4
                        })
                    )
                }
                toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
                if ((modaldata.type === "series" && (modaldata?.contents?.length === 1 || modaldata?.whole_series)) || modaldata?.type === "single") {
                    navigate(`${process.env.REACT_APP_URL_PREFIX}/approvedcontent`)
                } else if (modaldata?.contents?.length === 1 || modaldata.type === "content") {
                    if (modaldata.status === "revert") {
                        navigate(`${process.env.REACT_APP_URL_PREFIX}/rejectedcontent`)
                    } else {
                        navigate(`${process.env.REACT_APP_URL_PREFIX}/pendingcontent`)
                    }

                }
                closeModal()
                return true

            }

        } catch (err) {
            return toast.success(<Toaster message={err?.message} />, { hideProgressBar: true })
        }
    }

    return (
        <Fragment>
            <Modal
                isOpen={approve_content_modal.show}
                size='lg'
                toggle={() => {
                    closeModal()
                }
                }
                className='modal-dialog-centered p-5'>

                <ModalHeader toggle={() => {
                    closeModal()
                }} >
                </ModalHeader>
                <ModalBody className='ContentTypeModel'>
                    <Row>
                        <Col sm={12}>
                            <div className='deleteHeader text-center'>
                                <h5 className='mt-2 ' style={{ fontSize: "18px" }}>Are you sure want to {modaldata.status === "reject" ? "reject" : modaldata.status === "revert" ? "revert status of" : "approve"} this content?</h5>
                                {modaldata.status === "revert" ? <h6 className='mt-0' style={{ color: "#40c07b" }}>Note: This will move this title to Pending and will need to be Approved to be Published.</h6> : ""}
                                {modaldata.status === "reject" ? <>
                                    <h5 className=''>If yes, please provide a specific reason for the rejection.</h5>
                                    <h6 className='mt-2' style={{ color: "#40c07b" }}>Note:- Creator will be notified of the reason for rejection.

                                    </h6>
                                    <div className='mt-2'>
                                        <Controller
                                            id='reason'
                                            name='reason'
                                            defaultValue={""}
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    type="textarea"
                                                    {...field}
                                                    className='form-control'
                                                    invalid={errors.reason && true} />
                                            )} />
                                        {errors.reason && <FormFeedback>{errors.reason.message}</FormFeedback>}

                                    </div>
                                </> : ""}
                            </div>

                        </Col>
                        <Col sm={12} className="mt-3 text-center">
                            <Button type="submit" onClick={() => { modaldata.status === "reject" ? handleSubmit(handelFormSubmit)() : handelFormSubmit() }} className="submit submitReset mr-2" >{"Proceed"}</Button>
                            <Button type="button" color='grey' onClick={() => {
                                closeModal()
                            }} className="submit ml-2 submitReset">Cancel</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default ApproveContentModal