import React, {Fragment} from 'react'

const Toaster = ({message}) => {
  return (
    <Fragment>
            <div className='toastify-header'>
                <div className='title-wrapper'>
                    <h6 className='toast-title'>{message}</h6>
                </div>
            </div>
        </Fragment>
  )
}

export default Toaster