// ** Third Party Components
import Chart from 'react-apexcharts'
import Flatpickr from 'react-flatpickr'
import { Calendar } from 'react-feather'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody, CardSubtitle } from 'reactstrap'
import { useEffect, useRef, useState } from 'react'
import { Item } from 'react-contexify'
import moment from 'moment'

const ApexBarChart = ({ info, direction, data, filterValue, date_filter }) => {

  const [seriesData, setSeriesData] = useState([])
  const monthName = useRef([])

  const getMonthName = (number) => {
    const options = { month: 'long' }
    const date = new Date(2000, number - 1, 1)
    return date.toLocaleString('en-US', options)
  }

  // ** Chart Options
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      width: '400px'
    },
    plotOptions: {
      bar: {
        horizontal: false,
        barHeight: '30%',
        columnWidth: '70%', // Adjust the column width as needed
        borderRadius: [10],
        barWidth: '70%'
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    colors: info,
    dataLabels: {
      enabled: true
    },
    xaxis: {
      show: false,
      categories: monthName.current,
      labels: {
        show: false
      }
    },
    yaxis: {
      show: false,
      opposite: direction === 'rtl'

    },
    tooltip: {
      theme: "dark",
      enabled: true,
      shared: true,
      intersect: false
    }
  }

  function getStartAndEndMonthForQuarter(quarterNumber) {
    if (quarterNumber < 1 || quarterNumber > 4) {
      throw new Error("Quarter number must be between 1 and 4.")
    }

    const startMonth = ((quarterNumber - 1) * 3) + 1// Calculate the start month
    const endMonth = startMonth + 2// Calculate the end month
    return { startMonth, endMonth }
  }

  useEffect(() => {
    if (date_filter.status) {
      monthName.current = [`${moment(date_filter.data.startDate).format("DD MMM YYYY")} - ${moment(date_filter.data.endDate).format("DD MMM YYYY")}`]
      setSeriesData([date_filter.count])
    } else if (data.length) {
      let total_amt = 0
      let arr = []
      arr = data.map((Item, index) => {

        if (!index) monthName.current = []
        let value = ''
        switch (filterValue) {
          case 1:
            value = `Week ${Item.week}- ${getMonthName(Item.month)} ${Item.year}`
            break
          case 2:
            value = `${getMonthName(Item.month)} ${Item.year}`
            break
          case 4:
            value = `${Item.year}`
            break
          default:
            const { startMonth, endMonth } = getStartAndEndMonthForQuarter(Item.quarter)
            value = `${getMonthName(startMonth)} ${Item.year} - ${getMonthName(endMonth)} ${Item.year}`

            break
        }
        if (filterValue === 5) {
          total_amt += Item.total_amount
        }
        monthName.current = [...monthName.current, value]
        return parseFloat(Item.total_amount).toFixed(2)
      })

      if (filterValue === 5) {
        monthName.current = [`${data[0].year} - ${data[data.length - 1].year}`]
        arr = [parseFloat(total_amt).toFixed(2)]
      }
      setSeriesData(arr)
    }
  }, [data, date_filter])
  // ** Chart Series
  const series = [
    {
      name: "Customers",
      data: [...seriesData]
    }
  ]
  // Calculate the chart width based on the number of data points and column width
  const numDataPoints = seriesData.length
  const columnWidth = '70%' // Adjust the column width as needed
  const chartWidth = numDataPoints * parseInt(columnWidth)
  const divElement = document.getElementById('chart_div')
  const divWidth = divElement?.offsetWidth

  return (
    <div className='' id="chart_div" style={{ overflowX: "auto", overflowY: "hidden", width: '100%', maxHeight: '400px', marginBottom: "16px" }}>
      <div className='' style={{ width: `${divWidth >= chartWidth ? "100%" : `${chartWidth}px`}` }}>
        <Chart options={options} series={series} type='bar' width={`${divWidth >= chartWidth ? "100%" : `${chartWidth}px`}`} height={400} />
      </div>
    </div>
  )
}

export default ApexBarChart
