import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {axiosRequest} from '../http'
// import {setCookie, deleteCookie} from '../http/cookies'

const initialState = {
  isAuth: false,
  user:{},
  error: false,
  error_message: null,
  status_code: null,
  loading: false
}

export const handleLogin = createAsyncThunk(
  'ADMIN_LOGIN',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosRequest({sub_url: '/admin/login', data})
        // setCookie('auth_token', response.data.token)
        sessionStorage.setItem('auth_token', response.data.token)
        return {response}
      } catch (err) {
          if (!err.response) {
            throw err
          }
          return rejectWithValue(err.response)
      }
    }
)

export const handleLogout = createAsyncThunk(
  'ADMIN_LOGOUT',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/logout', data})
        // deleteCookie('auth_token')
        sessionStorage.clear()
        return response
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
  }
  }
)

export const getProfile =  createAsyncThunk(
  'ADMIN_PROFILE',
  async (data, {rejectWithValue}) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/myprofile', data})
      return {response}
    } catch (err) {
      if (err.response.data.logout) {
        sessionStorage.clear()
    }
      if (!err.response) {
          throw err
        }
        return rejectWithValue(err.response)
    }
  }
)


export const authSlice = createSlice({
  name: "AUTH_SLICE",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = false
      state.error_message = null
      state.status_code = null
    }
  },
  extraReducers: {
    [handleLogin.pending]: (state) => {
      state.loading = true
    },
    [handleLogin.rejected]: (state, action) => {
      state.loading = false
      state.error = true
      state.error_message = action.payload.data.message
      state.status_code = action.payload.status
    },
    [handleLogin.fulfilled]: (state, action) => {
      state.loading = false
      state.user = action.payload.response.data.data
      state.isAuth =  action.payload.response.data.status
    },
    [getProfile.pending]: (state) => {
      state.loading = true
    },
    [getProfile.rejected]: (state, action) => {
      state.loading = false
      state.error = true
      state.error_message = action.payload?.data?.message
      state.status_code = action.payload.status
    },
    [getProfile.fulfilled]: (state, action) => {
      state.loading = false
      state.user = action.payload.response.data.data
      state.isAuth =  action.payload.response.data.status
    },
    [handleLogout.pending]: (state) => {
      state.loading = true
    }, 
    [handleLogout.rejected]: (state, action) => {
      state.loading = false
      state.error = true
      state.error_message = action.payload.data.message
      state.status_code = action.payload.status

    }, 
    [handleLogout.fulfilled]: (state) => {
      state.loading = false
      state.user = {}
      state.isAuth =  false
    }
  }
})

export const {resetError} = authSlice.actions 
export default authSlice.reducer
