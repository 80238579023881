import React, { Fragment, useEffect, useState } from 'react'

// third party
import { Alert, Button, Row, Col, Badge } from 'reactstrap'
import { Link, useParams } from 'react-router-dom'

// ** redux
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from '../../../redux/content_slice'

// ** component
import ContentCard from '../../../components/contentCard'
import SeriesContentCard from '../../../components/seriesContentCard'

// ** Custom Components
import Toaster from "../../../components/toaster"
import toast from 'react-hot-toast'
import { axiosRequest } from '../../../http'
import Loader from '../../../components/loader'
import ApproveContentModal from '../../../components/modals/ApproveContentModal'
import StatsCard from '../../home/Cards/StatsCard'
import { Box } from 'react-feather'

const single_comic_data = [
  {
    value: "total_comics",
    title: '',
    subtitle: 'Total Comics',
    color: 'light-success',
    icon: <Box size={24} />
  },
  {
    value: "active_comics",
    title: '',
    subtitle: 'Total Active Comics',
    color: 'light-info',
    icon: <Box size={24} />
  },
  {
    value: "inactive_comics",
    title: '',
    subtitle: 'Total Inactive Comics ',
    color: 'light-info',
    icon: <Box size={24} />
  },
  {
    value: "pending_comics",
    title: '',
    subtitle: 'Total Pending Comics',
    color: 'light-info',
    icon: <Box size={24} />
  },
  {
    value: "rejected_comics",
    title: '',
    subtitle: 'Total Rejected Comics',
    color: 'light-info',
    icon: <Box size={24} />
  },
  {
    value: "paid_comics",
    title: '',
    subtitle: 'Total paid Comics',
    color: 'light-info',
    icon: <Box size={24} />
  },
  {
    value: "free_comics",
    title: '',
    subtitle: 'Total Free Comics',
    color: 'light-info',
    icon: <Box size={24} />
  }
]

const ContentView = () => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const dispatch = useDispatch()
  const store = useSelector(state => state.content_slice)
  const [contents, setcontents] = useState([])
  const [allDataArray, setAllDataArray] = useState([])
  const pending_content = sessionStorage.getItem("pending_content")
  const contents_type = sessionStorage.getItem("contents")


  useEffect(() => {
    return () => {
      sessionStorage.removeItem("pending_content")
      sessionStorage.removeItem("contents")
    }
  }, [])

  const handleGetSingleContent = async () => {
    try {
      const data = {
        series_id: id
      }
      const response = await axiosRequest({ sub_url: '/getseries', data })
      if (response.status === 200) {
        dispatch(
          selectData({
            data: response.data.data.series,
            contents: response.data.data.content
          })
        )
        setLoading(false)
      }

    } catch (err) {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (store.seriesContent.length) {
      if (pending_content === "reject") {
        const arr = store.seriesContent.filter(item => Number(item.status) === 4)
        setcontents(arr)
      } else if (pending_content === "true") {
        const arr = store.seriesContent.filter(item => Number(item.status) === 3)
        setcontents(arr)
      } else if (pending_content === "all") {
        setcontents(store.seriesContent)
      } else {
        const arr = store.seriesContent.filter(item => Number(item.status) !== 3 && Number(item.status) !== 4)
        setcontents(arr)
      }
    }

  }, [store.seriesContent, pending_content])
  useEffect(() => {
    if (Object.keys(store.selectedData).length === 0 || store.selectedData?.id !== id) {
      setLoading(true)
      handleGetSingleContent()
    }
  }, [])

  const handleAction = async (contentInfo) => {
    const admin_token = sessionStorage.getItem('auth_token')
    const data = {}
    let sub_url
    if (contentInfo.source_type === 1) {
      sub_url = '/login'
      data.email = contentInfo.email
      data.source_type = 1
    } else {
      sub_url = '/sociallogin'
      data.source_id = contentInfo.source_id
      data.source_type = contentInfo.source_type
      data.device_type = 0
    }

    try {
      const response = await axiosRequest({ sub_url, data })
      const url = `editseries/${contentInfo.slug}?type=1`
      if (response.status === 200) {
        window.open(`${process.env.REACT_APP_WEB_URL}?t=${response.data.body.token}&u=${response.data.body.logged_in_by}&at=${admin_token}&url=${url}`, '_blank', 'noopener,noreferrer')
      }
    } catch (err) {
      return toast.error(<Toaster message={err.response.message} />, { hideProgressBar: true })
    }

  }

  useEffect(() => {
    const count_details = {
      total_comics: 0,
      active_comics: 0,
      inactive_comics: 0,
      pending_comics: 0,
      rejected_comics: 0,
      paid_comics: 0,
      free_comics: 0
    }

    store.seriesContent.map((item) => {
      count_details.total_comics += 1
      if (item.status === 1) {
        count_details.active_comics += 1
      } else if (item.status === 2 || item.status === 0) {
        count_details.inactive_comics += 1
      } else if (item.status === 3) {
        count_details.pending_comics += 1
      } else if (item.status === 4) {
        count_details.rejected_comics += 1
      }

      if (item.is_paid) {
        count_details.paid_comics += 1
      } else if (!item.is_paid) {
        count_details.free_comics += 1
      }
    })


    const new_arr = single_comic_data.map(item => {
      Object.keys(count_details).map(curr => {
        if (curr === item.value) {
          item.title = count_details[curr]
        }
      })
      return item
    })

    setAllDataArray(new_arr)

  }, [store.seriesContent, pending_content])

  return loading ? (<Loader />) : (Object.keys(store?.selectedData).length === 0 && !loading) ? (
    <Alert color='danger'>
      <h4 className='alert-heading'>Contents not found</h4>
      <div className='alert-body'>
        Contents with id: {id} doesn't exist. Check list of all Contents: <Link to={`${process.env.REACT_APP_URL_PREFIX}/contents`}>Contents List</Link>
      </div>
    </Alert>
  ) : (store?.selectedData !== null || store?.selectedData !== undefined || Object.keys(store?.selectedData).length > 0) && !store.loading && (
    <Fragment>
      {/* Header Section */}

      <Row className='mb-2'>

        <Col sm={6}>
          <h3>Collection Details <Badge className='ms-2 f-12' color='success'>{store?.selectedData.content_type === 1 ? 'Comic' : 'Art'}</Badge></h3>
        </Col>
        <Col sm={6} className="text-align-right">
          <Button color='success' className='ms-auto' onClick={() => handleAction(store.selectedData)}>Login As {store.selectedData?.user_name !== "" ? store.selectedData?.user_name : `${store.selectedData?.first_name} ${store.selectedData?.last_name}`} </Button>
        </Col>
      </Row>
      {/* Header Section End */}
      {contents_type === "all" ? <Row className='mb-4'>
        <Col sm={12}>
          <StatsCard
            cols={{ sm: "12", lg: "6" }}
            data={allDataArray}
            component_title={`Details for: ${store.selectedData.title}`} />
        </Col>
      </Row> : ""}
      {/* Collection Details */}
      {(pending_content === "true" || pending_content === "reject") ? "" : <ContentCard data={store.selectedData} pending_content={pending_content} />}
      {/* Collection Details End */}

      {/* Collection's Episodes */}
      {contents.length > 0 && <div className='contentSection'>
        {
          contents.map((item, index) => {
            return (
              <Fragment>
                <h3> Episode {index + 1}</h3>
                <SeriesContentCard keys={index} data={item} pending_content={item.status === 3 ? "true" : item.status === 4 ? "reject" : "all"} contents={contents} />
              </Fragment>
            )
          })
        }
      </div>}
      <ApproveContentModal />
    </Fragment>
  )
}

export default ContentView