// ** React Import

// ** Custom Components
import Sidebar from '@components/sidebar'
import Toaster from "../../components/toaster"

// ** Third Party Components
import { useForm } from 'react-hook-form'
import toast from "react-hot-toast"

// ** Reactstrap Imports
import { Badge, Button, Form, Input } from 'reactstrap'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { axiosRequest } from '../../http'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { updatePayoutStatus } from '../../redux/payout_slice'

const Pay_user = ({ open, toggleSidebar }) => {


  // ** Store Vars
  const MySwal = withReactContent(Swal)
  const dispatch = useDispatch()
  const { selected_payout } = useSelector(state => state.payoutReducer)

  // ** Vars
  const {
    handleSubmit
  } = useForm()


  // ** Function to handle form submit
  const onSubmit = async () => {
    try {

      return MySwal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, Approve!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ms-1'
        },
        buttonsStyling: false
      }).then(async function (result) {

        if (result.isConfirmed) {
          const response = await axiosRequest({ sub_url: "/admin/approve_payment", data: { payout_id: selected_payout?.data?.id } })
          if (response.status === 200) {
            toggleSidebar()
            dispatch(
              updatePayoutStatus({
                status: 1,
                id: selected_payout?.data?.id
              })
            )
            return toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
          }
        }

      })


    } catch (err) {
      return toast.success(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
    }
  }

  return (
    <Sidebar
      size='lg'
      open={open}
      title={`Payout`}
      headerClassName='mb-1'
      contentClassName='pt-0'
      toggleSidebar={toggleSidebar}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>

        <div className='d-flex'>
          <h5>To :</h5> <span className='ms-1'>{selected_payout.data?.fullname}</span>
        </div>
        <div className='d-flex'>
          <h5>Paypal email : </h5> <span className='ms-1'>{selected_payout?.data?.paypal_id}</span>
        </div>

        <div className='d-flex'>
          <h5>Amount :</h5> <span className='ms-1'>{selected_payout?.data?.amount} USD</span>
        </div>
        {selected_payout?.data?.transaction_id ? <div className='d-flex'>
          <h5>Transaction Id :</h5> <span className='ms-1'>{selected_payout?.data?.transaction_id}</span>
        </div> : ""}
        <div className='d-flex'>
          <h5>status : </h5> <Badge className='cursor-pointerp mx-2 d-flex align-items-center mb-1' color={selected_payout?.data?.status === "0" ? 'info' : 'success'}>{selected_payout?.data?.status === "0" ? "Pending" : "Approved"}</Badge>
        </div>

        {/* {(selected_payout?.data?.status === 0 || selected_payout?.data?.status === "0") && <Button type='submit' disabled={selected_payout?.data?.status === "1" && true} className='me-1 mt-2' color='primary'>
          Approve
        </Button>} */}


        <Button type='reset' className='mt-2' color='secondary' outline onClick={toggleSidebar}>
          Cancel
        </Button>
      </Form>
    </Sidebar>
  )
}

export default Pay_user
