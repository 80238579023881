// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getSupportMessages = createAsyncThunk(
  'GET_SUPPORT_MESSAGE_LIST', 
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosRequest({sub_url: '/admin/getallsupportmessage', params})
      if (response.status === 200) {
        return { allData: response.data, response: response.data, totalPages: response.data.count, params }
      }
    } catch (err) {
      if (!err.response) {
          throw err
      }
      return rejectWithValue(err.response.data)
  }
  
})

export const support_message_slice = createSlice({
  name: 'SUPPORTMESSAGE',
  initialState: {
    data: [],
    selectedSupportMessage: {},
    allData: [],
    params: {},
    toastDetails: {
        toast_status: null,
        status_code: null,
        toast_message: null
    },
    total: 1
  },
  reducers: {
    selectMessage: (state, action) => {
      state.selectedSupportMessage = action.payload.support_message
      state.selectedSupportMessage = {...state.selectedSupportMessage, index: action.payload.index}
    },
    updateMessageStatus:(state, action) => {
      state.data[action.payload.index].status = action.payload.status
    }
  },
  extraReducers: {
    [getSupportMessages.pending]: (state) => {
        state.loading = true
        state.toastDetails.toast_status = null
        state.toastDetails.status_code = null
        state.toastDetails.toast_message = null
      },
      [getSupportMessages.rejected]: (state, action) => {
        state.loading = false
        state.toastDetails.toast_message = action.payload
        state.toastDetails.toast_status = true
        state.toastDetails.status_code = action.payload
      },
      [getSupportMessages.fulfilled]: (state, action) => {
        state.loading = false
        state.data = action.payload.response.data
        state.allData = action.payload.response.allData || []
        state.total = action.payload.response.count
        state.params = action.payload.params
      }
     
  }
})

export const {selectMessage, updateMessageStatus} = support_message_slice.actions
export default support_message_slice.reducer
